import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ControlsPageSize from 'views/learning/components/ControlsPageSize';
import ControlsAdd from '../assessment/components/ControlsAdd';
import ControlsEdit from '../assessment/components/ControlsEdit';
import ControlsDelete from '../assessment/components/ControlsDelete';
import ControlsSearch from 'views/learning/components/ControlsSearch';
import ModalAddAssessment from '../assessment/components/ModalAddAssessment';
import ModalEditAssessment from '../assessment/components/ModalEditAssessment';
import Table from '../assessment/components/Table';
import TablePagination from '../assessment/components/TablePagination';
import ControlsView from '../assessment/components/ControlsView';
import ModalViewAssessment from '../assessment/components/ModalViewAssessment';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// import questionsFile

import ControlsPageSizeComponent from 'views/learning/components/newControlPageSize';

import ModelAddAlerts from './ModelAddAlert';



const AlertsTable = ({ tableInstance }) => {
  const { isOpen, whichModal } = tableInstance;

  return (
    <Row>
      <Col>
        <div>
          <Row className="mb-3">
            <Col sm="12" md="5" lg="3" xxl="2">
              <div className="d-inline-block float-md-start me-1 mb-1 mb-md-0 search-input-container w-100 shadow bg-foreground">
                <ControlsSearch tableInstance={tableInstance} />
              </div>
            </Col>
            <Col sm="12" md="7" lg="9" xxl="10" className="text-end">
              <div className="d-inline-block me-0 me-sm-3 float-start float-md-none">
                <ControlsAdd tableInstance={tableInstance} />{' '}
                <ControlsDelete tableInstance={tableInstance} />
              </div>
              <div className="d-inline-block">
                <ControlsPageSizeComponent tableInstance={tableInstance} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Table className="react-table rows" tableInstance={tableInstance} />
            </Col>
            <Col xs="12">
              <TablePagination tableInstance={tableInstance} />
            </Col>
          </Row>
        </div>
        {isOpen && whichModal === 'add' && <ModelAddAlerts tableInstance={tableInstance} />}
        {/* {isOpen && whichModal === 'edit' && <ModelEditDayPlan tableInstance={tableInstance} />}
        {isOpen && whichModal === 'view' && <ModelViewHomeWork tableInstance={tableInstance} />} */}
      </Col>
    </Row>
  );
};

export default AlertsTable;
