import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const AssessmentApi = createApi({
  reducerPath: 'AssessmentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  endpoints: (builder) => ({
    getAllAssessment: builder.mutation({
      query: ({ ep, token }) => {
        console.log(ep, token);

        return {
          url: ep,
          method: 'POST',
          redirect: 'follow',
          body: JSON.parse(JSON.stringify({ origin: `${process.env.REACT_APP_ADMIN_APP}` })),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_ADMIN_APP}`,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers':
              'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
            'Access-Control-Allow-Credentials': 'true',
          },
        };
      },
      transformResponse: (response) => response,
    }),
    postAssessment: builder.mutation({
      query: ({ ep, values, token }) => {
        console.log(ep, values, token);
       

        return {
          url: ep,
          method: 'POST',
          redirect: 'follow',
          body: JSON.parse(
            JSON.stringify({ ...values })
          ),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_ADMIN_APP}`,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers':
              'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
            'Access-Control-Allow-Credentials': 'true',
          },
        };
      },
    }),
    postEditAssessment: builder.mutation({
      query: ({ ep, values, token }) => {
        console.log(ep, values, token);

        return {
          url: ep,
          method: 'POST',
          redirect: 'follow',
          body: JSON.parse(
            JSON.stringify({ ...values, origin: `${process.env.REACT_APP_ADMIN_APP}` })
          ),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_ADMIN_APP}`,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers':
              'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
            'Access-Control-Allow-Credentials': 'true',
          },
        };
      },
    }),
    getSeriesData: builder.mutation({
      query: ({ ep, values, token }) => {
        console.log(ep, values, token);

        return {
          url: ep,
          method: 'POST',
          redirect: 'follow',
          body: JSON.parse(
            JSON.stringify({ ...values, origin: `${process.env.REACT_APP_ADMIN_APP}` })
          ),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_ADMIN_APP}`,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers':
              'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
            'Access-Control-Allow-Credentials': 'true',
          },
        };
      },
    }),
    getApiData: builder.mutation({
      query: ({ ep, values, token }) => {
        console.log(ep, values, token);

        return {
          url: ep,
          method: 'POST',
          redirect: 'follow',
          body: JSON.parse(
            JSON.stringify({ ...values, origin: `${process.env.REACT_APP_ADMIN_APP}` })
          ),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_ADMIN_APP}`,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers':
              'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
            'Access-Control-Allow-Credentials': 'true',
          },
        };
      },
    }),
  }),
});

export const {
  useGetAllAssessmentMutation,
  usePostAssessmentMutation,
  usePostEditAssessmentMutation,
  useGetSeriesDataMutation,
  useGetApiDataMutation
} = AssessmentApi;

export const { AssessmentApiReducer } = AssessmentApi.reducer;
