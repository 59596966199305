import React, { useState, useEffect } from 'react';
import {
  useGetAllAssessmentMutation,
  usePostEditAssessmentMutation,
  usePostAssessmentMutation,
} from 'slices/get/assessment';
import useService from 'views/default/useService';
import { Badge, Col, Form, Row } from 'react-bootstrap';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useRowState,
} from 'react-table';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';


function useAssessment() {
  const title = 'Assessment';
  const description = 'Learning Assessment List';
  const [isOpenAddEditDeleteModal, setIsOpenAddEditDeleteModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [whichModal, setWhichModal] = useState('');
  const [editModalData, setEditModalData] = useState({});
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory()

  const [getAllAssessment] = useGetAllAssessmentMutation();
  const [postAssessment] = usePostAssessmentMutation();
  const [postEditAssessment] = usePostEditAssessmentMutation();
  const { checkAccessTime, fetchAccessToken } = useService();
  const [data, setData] = useState([]);

  useEffect(() => {
    const access_token = fetchAccessToken();

    getAllAssessment({ ep: '/assessment/data', token: access_token }).then((response) => {
      console.log(response);
      console.log('Custom data from after_request:', response);

      checkAccessTime(response, currentPath);
      setData(response.data.data);
    });
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Assessment Name',
        accessor: 'name',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase w-30',
        Cell: ({ cell }) => {
          return <div className="list-item-heading body">{cell.value}</div>;
        },
      },
      {
        Header: 'Status',
        accessor: 'active',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase w-10',
        Cell: ({ cell }) => {
          return (
            <Badge bg={`outline-${cell.value === 'on' ? 'primary' : 'danger'}`}>{cell.value}</Badge>
          );
        },
      },
      {
        Header: '',
        id: 'action',
        headerClassName: 'empty w-10',
        Cell: ({ row }) => {
          const { checked, onChange } = row.getToggleRowSelectedProps();
          return (
            <Form.Check
              className="form-check float-end mt-1"
              type="checkbox"
              checked={checked}
              onChange={onChange}
            />
          );
        },
      },
    ];
  }, []);

  const onOpenAddModal = () => {
    setIsOpen(true);
    setWhichModal('add');
  };

  const onOpenEditModal = (val) => {
    setIsOpen(true);
    setWhichModal('edit');
    setEditModalData(val);
  };

  const onOpenViewModal = (val) => {
    console.log('tableInstance..........',tableInstance.selectedFlatRows[0].original.id)
    let id = tableInstance.selectedFlatRows[0].original.id
    history.push({
      pathname: `/assessment/quesions/${id}`,
      state: { 
        id, // Passing the id to the next component
        value: tableInstance.selectedFlatRows[0].original // You can pass more data like val here if needed
      }
    });
    // let location = useLocation()
    // location.push('./assessmentQuetion')
    // setIsOpen(true);
    setWhichModal('view');
    setEditModalData(val);
  };

  const onCloseEmptyModal = () => {
    setIsOpen(false);
    setWhichModal('');
    setEditModalData({});
  };

  const onCloseAddModal = async (values) => {
    onCloseEmptyModal();
    console.log('onCloseAddModal', values);

    // setIsLoading(true);

    const access_token = fetchAccessToken();

    postAssessment({ ep: '/assessment/submit-form', values, token: access_token }).then(
      (response) => {
        console.log(response);
        console.log('Custom data from after_request:', response, response?.error);

        checkAccessTime(response, currentPath);
        setData(response.data.data);
      }
    );
  };

  const onCloseEditModal = async (values) => {
    onCloseEmptyModal();
    console.log('onCloseEditModal', values);

    // setIsLoading(true);

    const access_token = fetchAccessToken();

    postEditAssessment({ ep: '/assessment/submit-edit-form', values, token: access_token }).then(
      (response) => {
        // console.log(response);
        // console.log('Custom data from after_request:', response, response?.error);
        checkAccessTime(response, currentPath);
        setData(response.data.data);
      }
    );
  };

  const onCloseDeleteModal = async (values) => {
    console.log('onCloseDeleteModal', values);

    // setIsLoading(true);

    const access_token = fetchAccessToken();

    postEditAssessment({
      ep: '/assessment/submit-delete-form',
      values: { data: values },
      token: access_token,
    }).then((response) => {
      console.log(response);
      console.log('Custom data from after_request:', response, response?.error);

      checkAccessTime(response, currentPath);
      setData(response.data.data);
      onCloseEmptyModal();
    });
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      isOpenAddEditDeleteModal,
      setIsOpenAddEditDeleteModal,
      isOpen,
      setIsOpen,
      whichModal,
      setWhichModal,
      editModalData,
      setEditModalData,
      editModalData,
      setEditModalData,
      onOpenAddModal,
      onOpenEditModal,
      onOpenViewModal,
      onCloseEmptyModal,
      onCloseAddModal,
      onCloseEditModal,
      onCloseDeleteModal,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useRowState
  );

  return { title, description, data, tableInstance };
}

export default useAssessment;
