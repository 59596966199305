import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const SchoolApi = createApi({
  reducerPath: 'SchoolApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  endpoints: (builder) => ({
    getAllSchool: builder.mutation({
      query: ({ ep, token }) => {

        return {
          url: ep,
          method: 'POST',
          redirect: 'follow',
          body: JSON.parse(JSON.stringify({ origin: `${process.env.REACT_APP_ADMIN_APP}` })),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_ADMIN_APP}`,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers':
              'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
            'Access-Control-Allow-Credentials': 'true',
          },
        };
      },
      transformResponse: (response) => response,
    }),
    // postSchool: builder.mutation({
    //   query: ({ ep, values, token }) => {
    //     console.log(ep, values, token);

    //     return {
    //       url: ep,
    //       method: 'POST',
    //       redirect: 'follow',
    //       body: JSON.parse(
    //         JSON.stringify({ ...values, origin: `${process.env.REACT_APP_ADMIN_APP}` })
    //       ),
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         'Content-Type': 'application/json',
    //         'Access-Control-Allow-Origin': `${process.env.REACT_APP_ADMIN_APP}`,
    //         'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //         'Access-Control-Allow-Headers':
    //           'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
    //         'Access-Control-Allow-Credentials': 'true',
    //       },
    //     };
    //   },
    // }),
    postSchool: builder.mutation({
      query: ({ ep, formData, token }) => {

        return {
          url: ep,
          method: 'POST',
          redirect: 'follow',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_ADMIN_APP}`,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers':
              'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
            'Access-Control-Allow-Credentials': 'true',
          },
        };
      },
    }),
    postEditSchool: builder.mutation({
      query: ({ ep, values, token }) => {

        return {
          url: ep,
          method: 'POST',
          redirect: 'follow',
          body: JSON.parse(
            JSON.stringify({ ...values, origin: `${process.env.REACT_APP_ADMIN_APP}` })
          ),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_ADMIN_APP}`,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers':
              'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
            'Access-Control-Allow-Credentials': 'true',
          },
        };
      },
    }),
  }),
});

export const { useGetAllSchoolMutation, usePostSchoolMutation, usePostEditSchoolMutation } =
  SchoolApi;

export const { SchoolApiReducer } = SchoolApi.reducer;
