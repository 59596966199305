import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const LessonByRoleApi = createApi({
  reducerPath: 'lessonByRoleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getLessonByRole: builder.mutation({
      query: (values, signal) => ({
        url: `${process.env.REACT_APP_API_URL}/onlineClasses/dataByRole`,
        method: 'POST',
        redirect: 'follow',
        body: { ...values },
        signal,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'admin.infopublisher.in',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Access-Control-Allow-Headers':
            'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
          'Access-Control-Allow-Credentials': 'true',
        },
      }),
    }),
  }),
});

export const { useGetLessonByRoleMutation } = LessonByRoleApi;

export const { LessonByRoleApiReducer } = LessonByRoleApi.reducer;
