import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import Table from './Table';
import ControlsSearch from './ControlsSearch';
import ControlsPageSize from './ControlsPageSize';
import TablePagination from './TablePagination';
import { useHistory } from 'react-router-dom';
import Plyr from 'plyr-react';
import YouTube from 'react-youtube';

function extractVideoId({ url }) {
  console.log('URL:', url);

  const regex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);

  console.log(match);

  return match ? match[1] : null;
}

const PurePlyr = React.memo((url) => {
  console.log(url);

  const ref = useRef();

  useEffect(() => {
    // Access the plyr instance and play the video

    // if (ref.current && ref.current.plyr) {
    //   ref?.current?.plyr?.play();
    // }

    console.log(extractVideoId(url));
  }, []);

  const options = {
    // autoplay: true,
  };

  const videoSrc = {
    type: 'video',
    sources: [{ src: extractVideoId(url), provider: 'youtube' }],
    poster: '/img/video/video-large.webp',
  };

  return <Plyr source={videoSrc} ref={ref} options={options} />;
});

const BoxedVariationsStripeParts = ({ data, lesson }) => {
  const history = useHistory();
  console.log(data);
  const [largeLeftModalExample, setLargeLeftModalExample] = useState(false);
  const [liveVideoID, setLiveVideoID] = useState('');
  const [animatedVideoID, setAnimatedVideoID] = useState('');

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Lesson Name',
        accessor: 'part',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase w-30',
        Cell: ({ cell, row }) => {
          console.log(row);

          return (
            <button
              className="list-item-heading body nav-link"
              onClick={() => {
                console.log('popup');
                setLargeLeftModalExample(true);
                setLiveVideoID(row.original.live);
                setAnimatedVideoID(row.original.animation);
              }}
            >
              {lesson} - {cell.value}
            </button>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    console.log(liveVideoID, animatedVideoID);
  }, [liveVideoID, animatedVideoID]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, sortBy: [{ id: 'name', desc: true }] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
    },
  };

  return (
    <Row>
      <Modal
        className="modal-left"
        show={largeLeftModalExample}
        onHide={() => setLargeLeftModalExample(false)}
      >
        <Modal.Body>
          <h2>Live Video</h2>
          {liveVideoID && <PurePlyr url={liveVideoID} />}
          <h2>Animated Video</h2>
          {animatedVideoID && <PurePlyr url={animatedVideoID} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setLargeLeftModalExample(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Col sm="12" md="5" lg="3" xxl="2" className="mb-1">
        <div className="d-inline-block float-md-start me-1 search-input-container w-100 border border-separator bg-foreground search-sm">
          <ControlsSearch tableInstance={tableInstance} />
        </div>
      </Col>
      <Col sm="12" md="7" lg="9" xxl="10" className="text-end mb-1">
        <div className="d-inline-block">
          <ControlsPageSize tableInstance={tableInstance} />
        </div>
      </Col>

      <Col xs="12">
        <Table className="react-table rows stripe" tableInstance={tableInstance} />
      </Col>
      <Col xs="12">
        <TablePagination tableInstance={tableInstance} />
      </Col>
    </Row>
  );
};

export default BoxedVariationsStripeParts;
