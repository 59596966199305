import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik, FieldArray, Form as FormikForm, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
// import AssessmentApis from './assessmentApis';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import {
  useGetAllAssessmentMutation,
  usePostEditAssessmentMutation,
  usePostAssessmentMutation,
  useGetSeriesDataMutation,
  useGetApiDataMutation,
} from 'slices/get/assessment';
import useService from 'views/default/useService';
import { useLocation } from 'react-router-dom';
import _, { values } from 'lodash';
import { useGetAllCommonAdminInitialDataMutation, usePostCommonMutation } from 'slices/get/common';

const validationSchema = yup.object().shape({
  series_list: yup.array().min(1, 'Select at least one series'),
  standard_list: yup.array().max(1, 'Select at least one standard'),
  subject_list: yup.array().max(1, 'Select at least one subject'),
  lesson_list: yup.string().required('Lesson is required'),
  part_list: yup.array().of(
    yup.object().shape({
      part: yup.string().required('Part name is required'),
    })
  ),
  active: yup.string().required('Status is required'),
  excelFile: yup.mixed().required('Excel file is required'),
});

const Control = ({ children, label, ...props }) => {
  return (
    <components.Control {...props} className="form-floating">
      {children}
      <label>{label}</label>
    </components.Control>
  );
};

const ModelEditHomeWork = ({ tableInstance }) => {
  const [getAllAssessment] = useGetAllAssessmentMutation();
  const [getSeriesData] = useGetSeriesDataMutation();
  const [getAllCommonAdminInitialData] = useGetAllCommonAdminInitialDataMutation();
  const [postAssessment] = usePostAssessmentMutation();
  const [postCommon] = usePostCommonMutation();
  const [postEditAssessment] = usePostEditAssessmentMutation();
  const { checkAccessTime, fetchAccessToken } = useService();
  const location = useLocation();
  const currentPath = location.pathname;
  let { isOpen, onCloseEmptyModal, whichModal, onCloseAddModal, selectedFlatRows } = tableInstance;

  console.log('selectedFlatRows.....................', selectedFlatRows[0]?.original);
  const queryParams = new URLSearchParams(location.search);
  const subjectId = queryParams.get('subjectId');

  const [data, setData] = useState();
  const [seriesData, setSeriesdata] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [subjectData, setSubjectsdata] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [homeworkData, setHomeworkData] = useState([]);
  // setHomeworkData({ series: selectedOptions });
  const [requiredFields, setRequiredFields] = useState({
    seriesRequired: false,
    standardsRequired: false,
    subjectsRequired: false,
    lessonsRequired: false,
    schoolRequired: false,
    statusRequired: false,
    dateRequired: false,
    homeworkRequired: false,
  });

  let assessmentQuetionsData = {
    assessmentId: 10,
    questionAndOptions: [
      { question: '', options: '', answer: '' },
      { question: '', options: '', answer: '' },
    ],
  };
  const access_token = fetchAccessToken();
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (selectedFlatRows.length > 0) {
      let selectedData = selectedFlatRows[0]?.original;
      homeworkData.series = { value: selectedData.series_id, label: selectedData.series_name };
      homeworkData.school = { value: selectedData.school_id, label: selectedData.school_name };
      homeworkData.subjects = { value: selectedData.subject_id, label: selectedData.subject_name };
      homeworkData.standard = {
        value: selectedData.standard_id,
        label: selectedData.standard_name,
      };
      homeworkData.lessons = { value: selectedData.lesson_id, label: selectedData.lesson_name };
      homeworkData.homework = selectedData.homework;
      homeworkData.date = selectedData.date;
      homeworkData.active = selectedData.active;

      getSeriesData({
        ep: `/lesson/get_lessons?standard_id=${selectedData.standard_id}&subject_id=${selectedData.subject_id}&series_ids=${selectedData.series_id}`,
        token: access_token,
      }).then((response) => {
        if (response.data.data.length > 0) {
          let lessonOptions = response.data.data.map((each) => {
            if (each.active == 'on') {
              return { value: each.id, label: each.lesson };
            }
          });
          checkAccessTime(response, currentPath);
          setLessonData(lessonOptions);
        }
      });
    }

    getAllCommonAdminInitialData({ ep: '/standard/data', token: access_token }).then((response) => {
      if (response.data.data.length > 0) {
        let standarOptions = response.data.data.map((each) => {
          if (each.active == 'on') {
            return { value: each.id, label: each.name };
          }
        });
        checkAccessTime(response, currentPath);
        setStandardData(standarOptions);
      }
    });
    getAllCommonAdminInitialData({ ep: '/series/data', token: access_token }).then((response) => {
      if (response.data.data.length > 0) {
        checkAccessTime(response, currentPath);
        let seriesOption = response.data.data.map((each) => ({ value: each.id, label: each.name }));
        setSeriesdata(seriesOption);
      }
    });
    getAllCommonAdminInitialData({ ep: '/subject/data', token: access_token }).then((response) => {
      checkAccessTime(response, currentPath);
      if (response.data.data.length > 0) {
        let subjectsOption = response.data.data.map((each) => ({
          value: each.id,
          label: each.name,
        }));
        setSubjectsdata(subjectsOption);
      }
    });
    getAllCommonAdminInitialData({ ep: '/school/schools_list', token: access_token }).then(
      (response) => {
        if (response.data.data.length > 0) {
          let schoolOptions = response.data.data.map((each) => ({
            value: each.id,
            label: each.name,
          }));
          setSchoolData(schoolOptions);

          checkAccessTime(response, currentPath);
        }
      }
    );
  }, []); // The empty dependency array means the effect runs once after the initial render

  //   const initialValues = {
  //     series_list: [],
  //     standard_list: [],
  //     subject_list: [],
  //     lesson: '',
  //     part_list: [{ part: '', live: '', animation: '' }],
  //     image_list: [{ image: '' }],
  //     active: '',
  //     excelFile: null, // Add initial value for the file
  //   };

  const checkFields = (homeworkData) => {
    const newRequiredFields = _.cloneDeep(requiredFields);
    // if (homeworkData.length == 0) {
    //   newRequiredFields.seriesRequired = true;
    // }

    if (homeworkData.series == undefined || homeworkData.series.length == 0) {
      newRequiredFields.seriesRequired = true;
    }
    if (homeworkData.standard == undefined || homeworkData.standard.length == 0) {
      newRequiredFields.standardsRequired = true;
    }
    if (homeworkData.subjects == undefined || homeworkData.subjects.length == 0) {
      newRequiredFields.subjectsRequired = true;
    }
    if (homeworkData.lessons == undefined || homeworkData.lessons.length == 0) {
      newRequiredFields.lessonsRequired = true;
    }
    if (homeworkData.date == undefined || homeworkData.date.length == 0) {
      newRequiredFields.dateRequired = true;
    }
    if (homeworkData.homework == undefined || homeworkData.homework.length == 0) {
      newRequiredFields.homeworkRequired = true;
    }
    if (homeworkData.school == undefined || homeworkData.school.length == 0) {
      newRequiredFields.schoolRequired = true;
    }

    if (!homeworkData.active || homeworkData.active == undefined) {
      newRequiredFields.statusRequired = true;
    }

    setRequiredFields(newRequiredFields);
    if (
      !newRequiredFields.seriesRequired &&
      !newRequiredFields.standardsRequired &&
      !newRequiredFields.subjectsRequired &&
      !newRequiredFields.dateRequired &&
      !newRequiredFields.schoolRequired &&
      !newRequiredFields.lessonsRequired &&
      !newRequiredFields.statusRequired &&
      !newRequiredFields.homeworkRequired
    ) {
      return {
        status: true,
      };
    } else {
      return {
        status: false,
      };
    }
  };

  const newHandleSubmit = async () => {
    let checkAllRequiredFields = await checkFields(homeworkData);
    if (checkAllRequiredFields.status) {
      let values = {
        series_id: homeworkData.series.value,
        school_id: homeworkData.school.value,
        standard_id: homeworkData.standard.value,
        subject_id: subjectId,
        lesson_id: homeworkData.lessons.value,
        date: homeworkData.date,
        homework: homeworkData.homework,
        active: homeworkData.active,
        id: selectedFlatRows[0]?.original.homework_id,
      };

      postCommon({
        ep: '/standard/home_work_update',
        values,
        token: access_token,
      }).then((response) => {
        if (response.data.status) {
          setSuccessMessage(response.data);
          setIsSuccess(true);
          setTimeout(() => {
            onCloseAddModal();
            window.location.reload();
          }, [2000]);
        } else {
          setSuccessMessage(response.data);
          setIsSuccess(true);
        }
      });
    }
  };

  return (
    <>
      {
        <Formik
          //   initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log('Submitted values:', values);
            console.log('Selected series list:', values.series_list);
          }}
        >
          {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => (
            <FormikForm onSubmit={newHandleSubmit}>
              <Modal
                backdrop="static"
                keyboard={false}
                className="modal-right fade"
                show={isOpen}
                onHide={onCloseEmptyModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {whichModal.slice(0, 1).toUpperCase()}
                    {whichModal.slice(1)} Homework
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={seriesData}
                      onChange={(selectedOptions) => {
                        requiredFields.seriesRequired = false;
                        console.log('Selected Options:', selectedOptions);

                        setHomeworkData((prevData) => {
                          const updatedData = {
                            ...prevData,
                            series: selectedOptions, // Update 'series' in state
                          };

                          // Check if standard and subjects are defined
                          if (
                            updatedData.standard !== undefined &&
                            updatedData.subjects !== undefined
                          ) {
                            getSeriesData({
                              ep: `/lesson/get_lessons?standard_id=${updatedData.standard.value}&subject_id=${updatedData.subjects.value}&series_ids=${updatedData.series.value}`,
                              token: access_token,
                            }).then((response) => {
                              if (response.data.data.length > 0) {
                                let lessonOptions = response.data.data.map((each) => {
                                  if (each.active == 'on') {
                                    return { value: each.id, label: each.lesson };
                                  }
                                });
                                checkAccessTime(response, currentPath);
                                setLessonData(lessonOptions);
                              }
                            });
                          }

                          return updatedData;
                        });

                        // Optionally update required fields state
                        setRequiredFields({ ...requiredFields });
                      }}
                      value={homeworkData.series}
                      placeholder="Select Series"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />

                    {requiredFields.seriesRequired && (
                      <div className="d-block invalid-tooltip">
                        Please select at least one series
                      </div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={schoolData}
                      onChange={(selectedOptions) => {
                        requiredFields.schoolRequired = false;
                        setHomeworkData((prevData) => ({
                          ...prevData, // Spread the existing state
                          school: selectedOptions, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                      value={homeworkData.school}
                      placeholder="Select School"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.schoolRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select school</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={standardData}
                      onChange={(selectedOptions) => {
                        setHomeworkData((prevData) => {
                          const updatedData = {
                            ...prevData,
                            standard: selectedOptions, // Update 'series' in state
                          };

                          // Check if standard and subjects are defined
                          if (
                            updatedData.series != undefined &&
                            updatedData.series != undefined &&
                            updatedData.subjects != undefined
                          ) {
                            getSeriesData({
                              ep: '/lesson/get_lessons?standard_id=8&subject_id=2&series_ids=1',
                              token: access_token,
                            }).then((response) => {
                              if (response.data.data.length > 0) {
                                let lessonOptions = response.data.data.map((each) => {
                                  if (each.active == 'on') {
                                    return { value: each.id, label: each.lesson };
                                  }
                                });
                                checkAccessTime(response, currentPath);
                                setLessonData(lessonOptions);
                              }
                            });
                          }

                          return updatedData;
                        });

                        requiredFields.standardsRequired = false;

                        setRequiredFields(requiredFields);
                      }}
                      value={homeworkData.standard}
                      placeholder="Select Standard"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.standardsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select standard</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={subjectData}
                      isDisabled={true}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          backgroundColor: state.isDisabled ? '#f0f0f0' : base.backgroundColor,
                          borderColor: state.isDisabled ? '#d3d3d3' : base.borderColor,
                          cursor: state.isDisabled ? 'not-allowed' : base.cursor,
                          opacity: state.isDisabled ? 0.8 : 1,
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#999',
                        }),
                        singleValue: (base, state) => ({
                          ...base,
                          color: state.isDisabled ? '#999' : base.color,
                        }),
                      }}
                      onChange={(selectedOptions) => {
                        setHomeworkData((prevData) => ({
                          ...prevData,
                          subjects: selectedOptions,
                        }));

                        requiredFields.subjectsRequired = false;
                        setRequiredFields(requiredFields);
                      }}
                      value={homeworkData.subjects}
                      placeholder="Select Subject"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.subjectsRequired && (
                      <div className="d-block invalid-tooltip">Please select subject</div>
                    )}
                  </div>

                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={lessonData}
                      onChange={(selectedOptions) => {
                        // console.log("selectedOptions....",selectedOptions);
                        // setFieldValue('series_list', selectedOptions);
                        // requiredFields.seriesRequired = false
                        requiredFields.lessonsRequired = false;
                        // setHomeworkData({'standard' : selectedOptions})
                        setHomeworkData((prevData) => ({
                          ...prevData, // Spread the existing state
                          lessons: selectedOptions, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                      value={homeworkData.lessons}
                      placeholder="Select Lesson"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.lessonsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select lesson</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <DatePicker
                      selected={
                        homeworkData.date ? new Date(homeworkData.date.replace(/\//g, '-')) : null
                      }
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = format(date, 'yyyy/MM/dd');
                          setHomeworkData((prevData) => ({
                            ...prevData,
                            date: formattedDate,
                          }));
                          requiredFields.dateRequired = false;
                          setRequiredFields({ ...requiredFields });
                        }
                      }}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select Date"
                      className="form-control"
                    />
                    {requiredFields.dateRequired && (
                      <div className="d-block invalid-tooltip">Please select a date</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <textarea
                      style={{ height: '100px' }}
                      className="form-control"
                      name="homework"
                      placeholder="Enter homework details here"
                      value={homeworkData.homework || ''}
                      onChange={(event) => {
                        const homeworkValue = event.target.value;
                        setHomeworkData((prevData) => ({
                          ...prevData,
                          homework: homeworkValue,
                        }));

                        requiredFields.homeworkRequired = false;
                        setRequiredFields({ ...requiredFields });
                      }}
                    />
                    {requiredFields.homeworkRequired && (
                      <div className="d-block invalid-tooltip">Please enter homework</div>
                    )}
                  </div>
                  <div className="mb-3 filled tooltip-end-top">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="radio"
                      label="On"
                      value="on"
                      id="statusRadio1"
                      name="active"
                      checked={homeworkData.active === 'on'}
                      onChange={(event) => {
                        // setFieldValue('active', event.target.value);
                        requiredFields.statusRequired = false;
                        setHomeworkData((prevData) => ({
                          ...prevData, // Spread the existing state
                          active: event.target.value, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                    />
                    <Form.Check
                      type="radio"
                      label="Off"
                      value="off"
                      id="statusRadio2"
                      name="active"
                      checked={homeworkData.active === 'off'}
                      onChange={(event) => {
                        // setFieldValue('active', event.target.value);
                        requiredFields.statusRequired = false;
                        setHomeworkData((prevData) => ({
                          ...prevData, // Spread the existing state
                          active: event.target.value, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                    />
                    {requiredFields.statusRequired == true && (
                      <div className="d-block invalid-tooltip">Please select status</div>
                    )}
                  </div>
                </Modal.Body>

                {isSuccess && (
                  <div
                    className={`card mb-3 ${
                      successMessage.status ? 'bg-success' : 'bg-danger'
                    } text-white`} // Dynamically set the background color based on success or failure
                    style={{ marginTop: '5px' }}
                  >
                    <div className="card-body">
                      <p className="card-text">{successMessage.message}</p>
                    </div>
                  </div>
                )}
                <Modal.Footer>
                  <Button variant="outline-primary" onClick={onCloseEmptyModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={newHandleSubmit}>
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>
            </FormikForm>
          )}
        </Formik>
      }
    </>
  );
};

export default ModelEditHomeWork;
