import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import Table from './Table';
import ControlsSearch from './ControlsSearch';
import ControlsPageSize from './ControlsPageSize';
import TablePagination from './TablePagination';
import { useHistory } from 'react-router-dom';

const BoxedVariationsStripe = ({
  data,
  series,
  seriesID,
  standardID,
  standard,
  subject,
  subjectID,
}) => {
  const history = useHistory();

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Lesson Name',
        accessor: 'name',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase w-30',
        Cell: ({ cell, row }) => {
          return (
            <button
              className="list-item-heading body nav-link"
              onClick={() => {
                console.log(row, row.original);

                console.log(
                  `/learning/parts?series=${series}&seriesID=${seriesID}&standard=${standard}&standardID=${standardID}&subject=${subject}&subjectID=${subjectID}&lesson=${cell.value}&lessonID=${row.original.id}`
                );

                history.push(
                  `/learning/parts?series=${series}&seriesID=${seriesID}&standard=${standard}&standardID=${standardID}&subject=${subject}&subjectID=${subjectID}&lesson=${cell.value}&lessonID=${row.original.id}`
                );
              }}
            >
              {cell.value}
            </button>
          );
        },
      },
      {
        Header: 'Total Parts',
        accessor: 'parts_length',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase w-10',
        cellClassName: 'text-alternate',
        Cell: ({ cell }) => {
          return <div className="text-alternate">{cell.value}</div>;
        },
      },
    ];
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5, sortBy: [{ id: 'name', desc: true }] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Row>
      <Col sm="12" md="5" lg="3" xxl="2" className="mb-1">
        <div className="d-inline-block float-md-start me-1 search-input-container w-100 border border-separator bg-foreground search-sm">
          <ControlsSearch tableInstance={tableInstance} />
        </div>
      </Col>
      <Col sm="12" md="7" lg="9" xxl="10" className="text-end mb-1">
        <div className="d-inline-block">
          <ControlsPageSize tableInstance={tableInstance} />
        </div>
      </Col>

      <Col xs="12">
        <Table className="react-table rows stripe" tableInstance={tableInstance} />
      </Col>
      <Col xs="12">
        <TablePagination tableInstance={tableInstance} />
      </Col>
    </Row>
  );
};

export default BoxedVariationsStripe;
