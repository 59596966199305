/* eslint-disable no-nested-ternary */
import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import React, { useEffect, useRef } from 'react';

const ButtonsCheckAll = ({ tableInstance }) => {
  const checkAllRef = useRef(null);
  const {
    getToggleAllPageRowsSelectedProps,
    setData,
    data,
    selectedFlatRows,
    state: { selectedRowIds },
    onCloseDeleteModal,
  } = tableInstance;
  const { onChange, checked, indeterminate } = getToggleAllPageRowsSelectedProps();

  useEffect(() => {
    if (checkAllRef.current) {
      checkAllRef.current.indeterminate = indeterminate;
    }
    return () => {};
  }, [indeterminate]);

  const onClick = () => {
    console.log(
      selectedFlatRows,
      selectedFlatRows.map((v) => v.original)
    );
    onCloseDeleteModal(selectedFlatRows.map((v) => v.original));
  };

  return (
    <div className="">
      <Dropdown drop="down" as={ButtonGroup} className="ms-1 check-all-container" align="end">
        <Button
          variant="outline-primary"
          className="btn-custom-control p-0 ps-3 pe-2"
          onClick={onChange}
        >
          <Form.Check
            ref={checkAllRef}
            className="form-check float-end pt-0"
            type="checkbox"
            checked={checked}
            onChange={() => {}}
          />
        </Button>
        <Dropdown.Toggle split as={Button} variant="outline-primary" className="" />
        <Dropdown.Menu
          popperConfig={{
            modifiers: [
              {
                name: 'computeStyles',
                options: {
                  gpuAcceleration: false,
                },
              },
            ],
          }}
        >
          <Dropdown.Item disabled={selectedFlatRows.length === 0} onClick={onClick}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default ButtonsCheckAll;
