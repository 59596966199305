import React, { useState, useEffect } from 'react';
import {
  useGetAllAssessmentMutation,
  usePostEditAssessmentMutation,
  usePostAssessmentMutation,
} from 'slices/get/assessment';
import useService from 'views/default/useService';
import { Badge, Col, Form, Row } from 'react-bootstrap';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useRowState,
} from 'react-table';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetAllCommonAdminInitialDataMutation } from 'slices/get/common';
import { format } from 'date-fns';


function useHomework() {
  const title = 'Homework';
  const description = 'Homework List';
  const [isOpenAddEditDeleteModal, setIsOpenAddEditDeleteModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [whichModal, setWhichModal] = useState('');
  const [editModalData, setEditModalData] = useState({});
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory()

  const [getAllAssessment] = useGetAllAssessmentMutation();
  const [postAssessment] = usePostAssessmentMutation();
  const [postEditAssessment] = usePostEditAssessmentMutation();
  const { checkAccessTime, fetchAccessToken } = useService();
  const [getAllCommonAdminInitialData] = useGetAllCommonAdminInitialDataMutation()
  const [data, setData] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const subjectId = queryParams.get('subjectId');

  useEffect(() => {
    const access_token = fetchAccessToken();

    getAllCommonAdminInitialData({ ep: `/standard/home_work_data?subjectId=${subjectId}`, token: access_token }).then((response) => {
      if(response && response?.data && response?.data?.data && response.data.data.length > 0) {

      checkAccessTime(response, currentPath);
      setData(response.data.data);
      }
    });
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'School Name',
        accessor: 'school_name',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase w-30',
        Cell: ({ cell }) => {
          return <div className="list-item-heading body">{cell.value}</div>;
        },
      },
      {
        Header: 'Date',
        accessor: 'date',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase w-30',
        Cell: ({ cell }) => {
          return <div className="list-item-heading body">{format(new Date(cell.value), 'EEE, dd MMM yyyy')}</div>;
        },
      },
      {
        Header: 'Homework',
        accessor: 'homework',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase w-30',
        Cell: ({ cell }) => {
          return <div className="list-item-heading body">{cell.value}</div>;
        },
      },
      {
        Header: 'Status',
        accessor: 'active',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase w-10',
        Cell: ({ cell }) => {
          return (
            <Badge bg={`outline-${cell.value === 'on' ? 'primary' : 'danger'}`}>{cell.value}</Badge>
          );
        },
      },
      {
        Header: '',
        id: 'action',
        headerClassName: 'empty w-10',
        Cell: ({ row }) => {
          const { checked, onChange } = row.getToggleRowSelectedProps();
          return (
            <Form.Check
              className="form-check float-end mt-1"
              type="checkbox"
              checked={checked}
              onChange={onChange}
            />
          );
        },
      },
    ];
  }, []);

  const onOpenAddModal = () => {
    setIsOpen(true);
    setWhichModal('add');
  };

  const onOpenEditModal = (val) => {
    setIsOpen(true);
    setWhichModal('edit');
    setEditModalData(val);
  };

  const onOpenViewModal = (val) => {
    // console.log('tableInstance..........',tableInstance.selectedFlatRows[0].original.id)
    // let id = tableInstance.selectedFlatRows[0].original.id
    // history.push({
    //   pathname: `/assessment/quesions/${id}`,
    //   state: { 
    //     id, // Passing the id to the next component
    //     value: tableInstance.selectedFlatRows[0].original // You can pass more data like val here if needed
    //   }
    // });
    // let location = useLocation()
    // location.push('./assessmentQuetion')
    setIsOpen(true);
    setWhichModal('view');
    setEditModalData(val);
  };

  const onCloseEmptyModal = () => {
    setIsOpen(false);
    setWhichModal('');
    setEditModalData({});
  };

  const onCloseAddModal = async (values) => {
    onCloseEmptyModal();

    // setIsLoading(true);

    const access_token = fetchAccessToken();

    postAssessment({ ep: '/assessment/submit-form', values, token: access_token }).then(
      (response) => {
        

        checkAccessTime(response, currentPath);
        setData(response.data.data);
      }
    );
  };

  const onCloseEditModal = async (values) => {
    onCloseEmptyModal();

    // setIsLoading(true);

    const access_token = fetchAccessToken();

    postEditAssessment({ ep: '/assessment/submit-edit-form', values, token: access_token }).then(
      (response) => {
        // console.log(response);
        // console.log('Custom data from after_request:', response, response?.error);
        checkAccessTime(response, currentPath);
        setData(response.data.data);
      }
    );
  };

  const onCloseDeleteModal = async (values) => {

    // setIsLoading(true);
    let deletedIds = values.map(each=>each.homework_id)
    const access_token = fetchAccessToken();

    postEditAssessment({
      ep: '/standard/home_work_delete',
      values: { data: deletedIds },
      token: access_token,
    }).then((response) => {
      // onCloseAddModal();
      window.location.reload();
      checkAccessTime(response, currentPath);
      // setData(response.data.data);
      onCloseEmptyModal();
    });
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      isOpenAddEditDeleteModal,
      setIsOpenAddEditDeleteModal,
      isOpen,
      setIsOpen,
      whichModal,
      setWhichModal,
      editModalData,
      setEditModalData,
      editModalData,
      setEditModalData,
      onOpenAddModal,
      onOpenEditModal,
      onOpenViewModal,
      onCloseEmptyModal,
      onCloseAddModal,
      onCloseEditModal,
      onCloseDeleteModal,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useRowState
  );

  return { title, description, data, tableInstance };
}

export default useHomework;
