import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import AssessmentTable from './tables/assessment/AssessmentTable';
import useAssessment from './tables/assessment/useAssessment';
import ButtonsCheckAll from './tables/assessment/components/ButtonsCheckAll';
import useExam from './tables/assessment/useExams';
import ExamTable from './tables/assessment/ExamTable';
import useHomework from './tables/homework/useHomework';
import HomeworkTable from './tables/homework/homeworkTable';

const HomeWork = () => {
  const { title, description, data, tableInstance } = useHomework();
  // const {selectedFlatRows} = tableInstance
  // console.log('selectedFlatRows........',selectedFlatRows[0])

  const { onOpenAddModal } = tableInstance;
  const addButtonClick = () => {
    // toggleAllPageRowsSelected(false);
    // setIsOpenAddEditDeleteModal(true);

    onOpenAddModal();
  };

  return (
    <>
      <HtmlHead title={title} description={description} />

      <Col>
        <div className="page-title-container mb-3">
          <Row>
            <Col className="mb-2">
              <h1 className="mb-2 pb-0 display-4">{title}</h1>
              <div className="text-muted font-heading text-small">{description}</div>
            </Col>
            <Col xs="5" sm="auto" className="d-flex align-items-center justify-content-end">
              <Button
                variant="outline-primary"
                className="btn-icon btn-icon-start w-100 w-md-auto"
                onClick={addButtonClick}
              >
                <CsLineIcons icon="plus" /> <span>New Homework</span>
              </Button>

              <ButtonsCheckAll tableInstance={tableInstance} />
            </Col>
          </Row>
        </div>
        {/* Title End */}

        <div className="mb-5">
          {data && (
            <section className="scroll-section" id="stripe">
              <HomeworkTable tableInstance={tableInstance} />
            </section>
          )}
        </div>
      </Col>
    </>
  );
};

export default HomeWork;
