import { createSlice } from '@reduxjs/toolkit';

const standardSlice = createSlice({
  name: 'standard',
  initialState: {
    standardData: [],
  },
  reducers: {
    setStandardStateFull(state, action) {
      state.standardData = action.payload;
    },
  },
});

export const { setStandardStateFull } = standardSlice.actions;
export default standardSlice.reducer;
