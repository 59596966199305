import React, { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  assessment: yup.string().required('Assessment is required'),
  active: yup.string().required('Status is required'),
});

const ModalViewExam = ({ tableInstance }) => {
  const { isOpen, onCloseEmptyModal, whichModal, onCloseEditModal, selectedFlatRows } =
    tableInstance;

  useEffect(() => {
  }, [selectedFlatRows]);

  const initialValues = {
    id: selectedFlatRows[0].original.id,
    assessment: selectedFlatRows[0].original.name,
    active: selectedFlatRows[0].original.active,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => (
        <FormikForm>
          <Modal className="modal-right fade" show={isOpen} onHide={onCloseEmptyModal}>
            <Modal.Header>
              <Modal.Title>
                {whichModal.slice(0, 1).toUpperCase()}
                {whichModal.slice(1)} Exam
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {/* <CsLineIcons icon="list" /> */}
              {/* <Form.Label>Assessment Name</Form.Label> */}
              <Form.Floating className="mb-3 filled tooltip-end-top">
                <Form.Control
                  id="floatingInputCustom"
                  type="text"
                  name="assessment"
                  placeholder="Enter Assessment"
                  value={values.assessment}
                  disabled
                />
                <label htmlFor="floatingInputCustom">Assessment Name</label>

                {errors.assessment && touched.assessment && (
                  <div className="d-block invalid-tooltip">{errors.assessment}</div>
                )}
              </Form.Floating>

              <div className="mb-3 filled tooltip-end-top">
                <Form.Label>Status</Form.Label>
                <Form.Check
                  type="radio"
                  label="On"
                  value="on"
                  id="statusRadio1"
                  name="active"
                  checked={values.active === 'on'}
                  disabled
                />
                <Form.Check
                  type="radio"
                  label="Off"
                  value="off"
                  id="statusRadio2"
                  name="active"
                  checked={values.active === 'off'}
                  disabled
                />
                {errors.active && touched.active && (
                  <div className="d-block invalid-tooltip">{errors.active}</div>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="outline-primary" onClick={onCloseEmptyModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ModalViewExam;
