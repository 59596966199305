import { createSlice } from '@reduxjs/toolkit';

const subjectSlice = createSlice({
  name: 'subject',
  initialState: {
    subjectData: [],
  },
  reducers: {
    setSubjectStateFull(state, action) {
      state.subjectData = action.payload;
    },
  },
});

export const { setSubjectStateFull } = subjectSlice.actions;
export default subjectSlice.reducer;
