import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ControlsPageSize from './components/ControlsPageSize';
import ControlsAdd from './components/ControlsAdd';
import ControlsEdit from './components/ControlsEdit';
import ControlsDelete from './components/ControlsDelete';
import ControlsSearch from './components/ControlsSearch';
import ModalAddAssessment from './components/ModalAddAssessment';
import ModalEditAssessment from './components/ModalEditAssessment';
import Table from './components/Table';
import TablePagination from './components/TablePagination';
import ControlsView from './components/ControlsView';
import ModalViewAssessment from './components/ModalViewAssessment';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import questionsFile from './questions.xlsx';
import ModalAddExam from './ModelAddExam';
import ModalEditExam from './ModelEditExam';
import ModalViewExam from './ModelViewExam';



const ExamTable = ({ tableInstance }) => {
  const { isOpen, whichModal } = tableInstance;

  return (
    <Row>
      <Col>
        <div>
          <Row className="mb-3">
            <Col sm="12" md="5" lg="3" xxl="2">
              <div className="d-inline-block float-md-start me-1 mb-1 mb-md-0 search-input-container w-100 shadow bg-foreground">
                <ControlsSearch tableInstance={tableInstance} />
              </div>
            </Col>
            <Col sm="12" md="7" lg="9" xxl="10" className="text-end">
              <div className="d-inline-block me-0 me-sm-3 float-start float-md-none">
                     
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top-add">Download Sample File</Tooltip>}>
      <a href={questionsFile} download="questions.xlsx">
        <Button
          style={{ marginRight: '5px' }}
          variant="foreground-alternate"
          className="btn-icon btn-icon-only shadow add-datatable"
        >
          <CsLineIcons icon="download" />
        </Button>
      </a>
    </OverlayTrigger>
                <ControlsAdd tableInstance={tableInstance} />{' '}
                <ControlsEdit tableInstance={tableInstance} />{' '}
                <ControlsView tableInstance={tableInstance} />{' '}
                <ControlsDelete tableInstance={tableInstance} />
              </div>
              <div className="d-inline-block">
                <ControlsPageSize tableInstance={tableInstance} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Table className="react-table rows" tableInstance={tableInstance} />
            </Col>
            <Col xs="12">
              <TablePagination tableInstance={tableInstance} />
            </Col>
          </Row>
        </div>
        {isOpen && whichModal === 'add' && <ModalAddExam tableInstance={tableInstance} />}
        {isOpen && whichModal === 'edit' && <ModalEditExam tableInstance={tableInstance} />}
        {isOpen && whichModal === 'view' && <ModalViewExam tableInstance={tableInstance} />}
      </Col>
    </Row>
  );
};

export default ExamTable;
