import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const ControlsDelete = ({ tableInstance }) => {
  const {
    selectedFlatRows,
    data,
    state: { selectedRowIds },
    onCloseDeleteModal,
  } = tableInstance;
  const onClick = () => {
    console.log(
      selectedFlatRows,
      selectedFlatRows.map((v) => v.original)
    );
    onCloseDeleteModal(selectedFlatRows.map((v) => v.original));
  };

  if (selectedFlatRows.length === 0) {
    return (
      <Button
        variant="foreground-alternate"
        className="btn-icon btn-icon-only shadow delete-datatable"
        disabled
      >
        <CsLineIcons icon="bin" />
      </Button>
    );
  }

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top-delete">Delete</Tooltip>}>
      <Button
        onClick={onClick}
        variant="foreground-alternate"
        className="btn-icon btn-icon-only shadow delete-datatable"
      >
        <CsLineIcons icon="bin" />
      </Button>
    </OverlayTrigger>
  );
};
export default ControlsDelete;
