import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik, FieldArray, Form as FormikForm, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
// import AssessmentApis from './assessmentApis';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import {
  useGetAllAssessmentMutation,
  usePostEditAssessmentMutation,
  usePostAssessmentMutation,
  useGetSeriesDataMutation,
  useGetApiDataMutation,
} from 'slices/get/assessment';
import useService from 'views/default/useService';
import { useLocation } from 'react-router-dom';
import _, { values } from 'lodash';
import { useGetAllCommonAdminInitialDataMutation, usePostCommonMutation } from 'slices/get/common';

const validationSchema = yup.object().shape({
  series_list: yup.array().min(1, 'Select at least one series'),
  standard_list: yup.array().max(1, 'Select at least one standard'),
  subject_list: yup.array().max(1, 'Select at least one subject'),
  lesson_list: yup.string().required('Lesson is required'),
  part_list: yup.array().of(
    yup.object().shape({
      part: yup.string().required('Part name is required'),
    })
  ),
  active: yup.string().required('Status is required'),
  excelFile: yup.mixed().required('Excel file is required'),
});

const Control = ({ children, label, ...props }) => {
  return (
    <components.Control {...props} className="form-floating">
      {children}
      <label>{label}</label>
    </components.Control>
  );
};

const ModelAddAlert = ({ tableInstance }) => {
  const [getAllAssessment] = useGetAllAssessmentMutation();
  const [getSeriesData] = useGetSeriesDataMutation();
  const [getAllCommonAdminInitialData] = useGetAllCommonAdminInitialDataMutation();
  const [postAssessment] = usePostAssessmentMutation();
  const [postCommon] = usePostCommonMutation();
  const [postEditAssessment] = usePostEditAssessmentMutation();
  const { checkAccessTime, fetchAccessToken } = useService();
  const location = useLocation();
  const currentPath = location.pathname;
  let {
    isOpen,
    onCloseEmptyModal,
    whichModal,
    onCloseAddModal,

    // seriesData,
    // standardData,
    // subjectData,
    // selectedSeries,
    // selectedStandard,
    // selectedSubject,
    // setSelectedSeries,
    // setSelectedStandard,
    // setSelectedSubject,
  } = tableInstance;
  const queryParams = new URLSearchParams(location.search);
  const subjectId = queryParams.get('subjectId');

  const [data, setData] = useState();
  const [seriesData, setSeriesdata] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [subjectData, setSubjectsdata] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const [alertTypeData,setAlertTypeData] = useState([])
  // setAlertData({ series: selectedOptions });
  const [requiredFields, setRequiredFields] = useState({
    schoolRequired: false,
    statusRequired: false,
    // startDateRequired: false,
    // endDateRequired:false,
    titleRequired : false,
    alertRequired: false,
    teacherRequired : false,
    standardRequired : false,
    studentRequired : false,
    alertTypeRequired : false
  });

  const access_token = fetchAccessToken();
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    // getAllAssessment({ ep: '/assessment/data', token: access_token }).then((response) => {
    //   console.log(response);
    //   console.log('Custom data from after_request:', response);

    //   checkAccessTime(response, currentPath);
    //   setData(response.data.data);
    // });
    getAllCommonAdminInitialData({ ep: '/standard/data', token: access_token }).then((response) => {
      if (response.data.data.length > 0) {
        let standarOptions = response.data.data.map((each) => {
          if (each.active == 'on') {
            return { value: each.id, label: each.name };
          }
        });
        standarOptions.unshift({value: 'all', label: 'All'})
        checkAccessTime(response, currentPath);
        setStandardData(standarOptions);
      }
    });
    getAllCommonAdminInitialData({ ep: '/alerts/alert_category', token: access_token }).then((response) => {
      if (response.data.data.length > 0) {
        checkAccessTime(response, currentPath);
        let seriesOption = response.data.data.map((each) => ({ value: each.id, label: each.name }));
        
        setAlertTypeData(seriesOption);
      }
    });
    // getAllCommonAdminInitialData({ ep: '/subject/data', token: access_token }).then((response) => {
    //   checkAccessTime(response, currentPath);
    //   if (response.data.data.length > 0) {
    //     let subjectsOption = response.data.data.map((each) => ({
    //       value: each.id,
    //       label: each.name,
    //     }));
    //     setSubjectsdata(subjectsOption);
    //   }
    // });
    getAllCommonAdminInitialData({ ep: '/school/schools_list', token: access_token }).then(
      (response) => {
        if (response.data.data.length > 0) {
          let schoolOptions = response.data.data.map((each) => ({
            value: each.id,
            label: each.name,
          }));
          schoolOptions.unshift({value: 'all', label: 'All'})
          setSchoolData(schoolOptions);
          checkAccessTime(response, currentPath);
        }
      }
    );
  }, []); // The empty dependency array means the effect runs once after the initial render

  const [excelData, setExcelData] = useState([]);

  const checkFields = (alertData) => {
    const newRequiredFields = _.cloneDeep(requiredFields);
    if (alertData.length == 0) {
      newRequiredFields.seriesRequired = true;
    }

    // if (alertData.series == undefined || alertData.series.length == 0) {
    //   newRequiredFields.seriesRequired = true;
    // }
    if (alertData.alertType == undefined || alertData.alertType.length == 0) {
      newRequiredFields.alertTypeRequired = true;
    }
    if(alertData.alertType?.value != 6){
      if (alertData.school == undefined || alertData.school.length == 0) {
        newRequiredFields.schoolRequired = true;
      }
    }

    if(alertData.alertType?.value == 2){
      if (alertData.teacher == undefined || alertData.teacher.length == 0) {
        newRequiredFields.teacherRequired = true;
      }
    }
    
    if(alertData.alertType?.value == 3 || alertData.alertType?.value == 5){
      if (alertData.standard == undefined || alertData.standard.length == 0) {
        newRequiredFields.standardRequired = true;
      }
    }
    
    if(alertData.alertType?.value == 5){
      if (alertData.student == undefined || alertData.student.length == 0) {
        newRequiredFields.studentRequired = true;
      }
    }
    
    if (alertData.alert == undefined || alertData.alert.length == 0) {
      newRequiredFields.alertRequired = true;
    }
    // if (alertData.startDate == undefined || alertData.startDate.length == 0) {
    //   newRequiredFields.startDateRequired = true;
    // }
    // if (alertData.endDate == undefined || alertData.endDate.length == 0) {
    //   newRequiredFields.endDateRequired = true;
    // }
    if (alertData.title == undefined || alertData.title.length == 0) {
      newRequiredFields.titleRequired = true;
    }
    

    if (!alertData.active || alertData.active == undefined) {
      newRequiredFields.statusRequired = true;
    }

    setRequiredFields(newRequiredFields);
    if (
      !newRequiredFields.alertTypeRequired &&
      !newRequiredFields.schoolRequired && !newRequiredFields.teacherRequired && !newRequiredFields.standardRequired &&
      !newRequiredFields.statusRequired && !newRequiredFields.studentRequired && !newRequiredFields.titleRequired
    ) {
      return {
        status: true,
      };
    } else {
      return {
        status: false,
      };
    }
  };

  const newHandleSubmit = async () => {
    let checkAllRequiredFields = await checkFields(alertData);
    if (checkAllRequiredFields.status) {
      let values = {
        alert_type : alertData.alertType?.value,
        // series_id: alertData.series,
        school_id: alertData.school ? alertData.school : null ,
        standard_id: alertData.standard ? alertData.standard : null,
        teacher_id: alertData.teacher ? alertData.teacher : null,
        student_id: alertData.student ? alertData.student : null,
        start_date : alertData.startDate ? alertData.startDate : null,
        end_date : alertData.endDate ? alertData.endDate : null,
        title : alertData.title,
        alert: alertData.alert,
        active: alertData.active,
      };


      postCommon({
        ep: '/alerts/alert_created',
        values,
        token: access_token,
      }).then((response) => {
        if (response.data.status) {
          setSuccessMessage(response.data);
          setIsSuccess(true);
          setTimeout(() => {
            onCloseAddModal();
            window.location.reload();
          }, [2000]);
        } else {
          setSuccessMessage(response.data);
          setIsSuccess(true);
        }
      });
    }
    // console.log(response);
    // console.log('post response', response);
    // checkAccessTime(response, currentPath);
    // setStandardData(standarOptions);
  };

  return (
    <>
      {
        <Formik
          // initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log('Submitted values:', values);
            console.log('Selected series list:', values.series_list);
          }}
        >
          {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => (
            <FormikForm onSubmit={newHandleSubmit}>
              <Modal
                backdrop="static"
                keyboard={false}
                className="modal-right fade"
                show={isOpen}
                onHide={onCloseEmptyModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {whichModal.slice(0, 1).toUpperCase()}
                    {whichModal.slice(1)} Alert
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={alertTypeData}
                      onChange={(selectedOptions) => {
                        requiredFields.alertTypeRequired = false;
                      
                        setAlertData({
                          alertType: selectedOptions,  // Update the selected alert type
                          school: [],                  // Reset other fields
                          teacher: [],
                          standard: [],
                          student: [],
                          startDate: '',
                          endDate: '',
                          title: '',
                          alert: '',
                          active: '',
                        });
                        setRequiredFields(requiredFields);
                      }}
                      value={alertData.alertType}
                      placeholder="Select Alert Type"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.alertTypeRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select alert type</div>
                    )}
                  </div>
                  {/* <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      isMulti
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={seriesData}
                      onChange={(selectedOptions) => {
                        requiredFields.seriesRequired = false;
                        console.log('Selected Options:', selectedOptions);

                        setAlertData((prevData) => {
                          const updatedData = {
                            ...prevData,
                            series: selectedOptions, // Update 'series' in state
                          };

                          // Check if standard and subjects are defined

                          return updatedData;
                        });

                        // Optionally update required fields state
                        setRequiredFields({ ...requiredFields });
                      }}
                      value={alertData.series}
                      placeholder="Select Series"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />

                    {requiredFields.seriesRequired && (
                      <div className="d-block invalid-tooltip">
                        Please select at least one series
                      </div>
                    )}
                  </div> */}
                   {alertData.alertType?.value !== 6 &&  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      isMulti
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={schoolData}
                      onChange={(selectedOptions) => {
                        requiredFields.schoolRequired = false;
                        const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];
                        if(selectedValues.length > 0 && alertData.alertType?.value == '2'){
                          postCommon({
                            ep: '/teacher/school_teachers',
                            values : {school_ids : selectedValues},
                            token: access_token,
                          }).then(
                            (response) => {
                              if (response.data.data.length > 0) {
                                let teacherOptions = response.data.data.map((each) => ({
                                  value: each.id,
                                  label: each.name,
                                }));
                                teacherOptions.unshift({value: 'all', label: 'All'})
                                setTeacherData(teacherOptions);
                                checkAccessTime(response, currentPath);
                              }
                            }
                          );
                        }
                        if(selectedValues.length > 0 && alertData.alertType?.value == '5'){
                          postCommon({
                            ep: '/student/School_student_list',
                            values : {school_ids : selectedValues},
                            token: access_token,
                          }).then(
                            (response) => {
                              if (response.data.data.length > 0) {
                                let studentOptions = response.data.data.map((each) => ({
                                  value: each.id,
                                  label: each.name,
                                }));
                                studentOptions.unshift({value: 'all', label: 'All'})
                                setStudentData(studentOptions);
                                checkAccessTime(response, currentPath);
                              }
                            }
                          );
                        }
                        
                        setAlertData((prevData) => ({
                          ...prevData, // Spread the existing state
                          school: selectedOptions, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                      value={alertData.school}
                      placeholder="Select School"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.schoolRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select school</div>
                    )}
                  </div>}
                  { alertData.alertType?.value == 2 &&
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      isMulti
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={teacherData}
                      onChange={(selectedOptions) => {
                        requiredFields.teacherRequired = false;
                        setAlertData((prevData) => ({
                          ...prevData, // Spread the existing state
                          teacher: selectedOptions, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                      value={alertData.teacher}
                      placeholder="Select Teacher"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.teacherRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select teacher</div>
                    )}
                  </div> }
                  {(alertData.alertType?.value == 3 || alertData.alertType?.value == 5) && <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      isMulti
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={standardData}
                      onChange={(selectedOptions) => {
                        requiredFields.standardRequired = false;
                        let values = {}
                        const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];
                        if(selectedOptions.length > 0){
                         
                          values.standard_ids = selectedValues
                        }
                        if(alertData.school.length > 0){
                          const selectedSchools = alertData.school ? alertData.school.map(opt => opt.value) : [];
                          values.school_ids = selectedSchools
                        }
                        setRequiredFields(requiredFields)
                        postCommon({
                          ep: '/student/School_student_list',
                          values,
                          token: access_token,
                        }).then(
                          (response) => {
                            if (response.data.data.length > 0) {
                              let studentOptions = response.data.data.map((each) => ({
                                value: each.id,
                                label: each.name,
                              }));
                              studentOptions.unshift({value: 'all', label: 'All'})
                              setStudentData(studentOptions);
                              checkAccessTime(response, currentPath);
                            }
                          }
                        );
                        setAlertData((prevData) => {
                          const updatedData = {
                            ...prevData,
                            standard: selectedOptions, // Update 'series' in state
                          };

                          // Check if standard and subjects are defined
                          return updatedData;
                        });
                      }}
                      value={alertData.standard}
                      placeholder="Select Standard"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.standardRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select standard</div>
                    )}
                  </div> }

                  {alertData.alertType?.value == 5 &&  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      isMulti
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={studentData}
                      onChange={(selectedOptions) => {
                        requiredFields.studentRequired = false;
                        setAlertData((prevData) => ({
                          ...prevData, // Spread the existing state
                          student: selectedOptions, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                      value={alertData.student}
                      placeholder="Select Student"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.studentRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select student</div>
                    )}
                  </div> }
                  <div className="mb-3 position-relative tooltip-end-top">
                    <DatePicker
                      selected={
                        alertData.startDate ? new Date(alertData.startDate.replace(/\//g, '-')) : null
                      }
                      onChange={(startDate) => {
                        if (startDate) {
                          const formattedDate = format(startDate, 'yyyy/MM/dd');
                          setAlertData((prevData) => ({
                            ...prevData,
                            startDate: formattedDate,
                          }));
                          requiredFields.dateRequired = false;
                          // setRequiredFields({ ...requiredFields });
                        }
                      }}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select Start Date"
                      className="form-control"
                    />
                    {/* {requiredFields.dateRequired && (
                      <div className="d-block invalid-tooltip">Please select a date</div>
                    )} */}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <DatePicker
                      selected={
                        alertData.endDate ? new Date(alertData.endDate.replace(/\//g, '-')) : null
                      }
                      onChange={(endDate) => {
                        if (endDate) {
                          const formattedDate = format(endDate, 'yyyy/MM/dd');
                          setAlertData((prevData) => ({
                            ...prevData,
                            endDate: formattedDate,
                          }));
                          // requiredFields.dateRequired = false;
                          // setRequiredFields({ ...requiredFields });
                        }
                      }}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select End Date"
                      className="form-control"
                    />
                    {/* {requiredFields.dateRequired && (
                      <div className="d-block invalid-tooltip">Please select a date</div>
                    )} */}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                          <input
                            type="text"
                            className={`form-control ${
                              requiredFields?.titleRequired ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter Title"
                            value={alertData.title || ''}
                            onChange={(e) => {
                              let titleValue = e.target.value;
                              setAlertData((prevData) => ({
                                ...prevData,
                                title: titleValue,
                              }));

                        
                              
                            requiredFields.titleRequired = false;
                            setRequiredFields({ ...requiredFields });
                            }}
                          />
                          {requiredFields?.titleRequired && (
                            <div className="d-block invalid-tooltip">Please enter title</div>
                          )}
                          </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <textarea
                      style={{ height: '100px' }}
                      className="form-control"
                      name="alert"
                      placeholder="Enter Alert here"
                      value={alertData.alert || ''}
                      onChange={(event) => {
                        const homeworkValue = event.target.value;
                        setAlertData((prevData) => ({
                          ...prevData,
                          alert: homeworkValue,
                        }));

                        requiredFields.alertRequired = false;
                        setRequiredFields({ ...requiredFields });
                      }}
                    />
                    {requiredFields.alertRequired && (
                      <div className="d-block invalid-tooltip">Please enter Alert</div>
                    )}
                  </div>
                  <div className="mb-3 filled tooltip-end-top">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="radio"
                      label="On"
                      value="on"
                      id="statusRadio1"
                      name="active"
                      checked={alertData.active === 'on'}
                      onChange={(event) => {
                        // setFieldValue('active', event.target.value);
                        requiredFields.statusRequired = false;
                        setAlertData((prevData) => ({
                          ...prevData, // Spread the existing state
                          active: event.target.value, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                    />
                    <Form.Check
                      type="radio"
                      label="Off"
                      value="off"
                      id="statusRadio2"
                      name="active"
                      checked={alertData.active === 'off'}
                      onChange={(event) => {
                        // setFieldValue('active', event.target.value);
                        requiredFields.statusRequired = false;
                        setAlertData((prevData) => ({
                          ...prevData, // Spread the existing state
                          active: event.target.value, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                    />
                    {requiredFields.statusRequired == true && (
                      <div className="d-block invalid-tooltip">Please select status</div>
                    )}
                  </div>
                </Modal.Body>

                {isSuccess && (
                  <div
                    className={`card mb-3 ${
                      successMessage.status ? 'bg-success' : 'bg-danger'
                    } text-white`} // Dynamically set the background color based on success or failure
                    style={{ marginTop: '5px' }}
                  >
                    <div className="card-body">
                      <p className="card-text">{successMessage.message}</p>
                    </div>
                  </div>
                )}
                <Modal.Footer>
                  <Button variant="outline-primary" onClick={onCloseEmptyModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={newHandleSubmit}>
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>
            </FormikForm>
          )}
        </Formik>
      }
    </>
  );
};
export default ModelAddAlert;
