import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik, FieldArray, Form as FormikForm, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
// import AssessmentApis from './assessmentApis';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import {
  useGetAllAssessmentMutation,
  usePostEditAssessmentMutation,
  usePostAssessmentMutation,
  useGetSeriesDataMutation,
  useGetApiDataMutation,
} from 'slices/get/assessment';
import useService from 'views/default/useService';
import { useLocation } from 'react-router-dom';
import _, { values } from 'lodash';
import { useGetAllCommonAdminInitialDataMutation, usePostCommonMutation } from 'slices/get/common';

const validationSchema = yup.object().shape({
  series_list: yup.array().min(1, 'Select at least one series'),
  standard_list: yup.array().max(1, 'Select at least one standard'),
  subject_list: yup.array().max(1, 'Select at least one subject'),
  lesson_list: yup.string().required('Lesson is required'),
  part_list: yup.array().of(
    yup.object().shape({
      part: yup.string().required('Part name is required'),
    })
  ),
  active: yup.string().required('Status is required'),
  excelFile: yup.mixed().required('Excel file is required'),
});

const Control = ({ children, label, ...props }) => {
  return (
    <components.Control {...props} className="form-floating">
      {children}
      <label>{label}</label>
    </components.Control>
  );
};

const ModelViewHomeWork = ({ tableInstance }) => {
  const [getAllAssessment] = useGetAllAssessmentMutation();
  const [getSeriesData] = useGetSeriesDataMutation();
  const [getAllCommonAdminInitialData] = useGetAllCommonAdminInitialDataMutation();
  const [postAssessment] = usePostAssessmentMutation();
  const [postCommon] = usePostCommonMutation();
  const [postEditAssessment] = usePostEditAssessmentMutation();
  const { checkAccessTime, fetchAccessToken } = useService();
  const location = useLocation();
  const currentPath = location.pathname;
  let { isOpen, onCloseEmptyModal, whichModal, onCloseAddModal, selectedFlatRows } = tableInstance;

  const queryParams = new URLSearchParams(location.search);
  const subjectId = queryParams.get('subjectId');

  const [data, setData] = useState();
  const [seriesData, setSeriesdata] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [subjectData, setSubjectsdata] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [homeworkData, setHomeworkData] = useState([]);
  // setHomeworkData({ series: selectedOptions });
  const [requiredFields, setRequiredFields] = useState({
    seriesRequired: false,
    standardsRequired: false,
    subjectsRequired: false,
    lessonsRequired: false,
    schoolRequired: false,
    statusRequired: false,
    dateRequired: false,
    homeworkRequired: false,
  });

  let assessmentQuetionsData = {
    assessmentId: 10,
    questionAndOptions: [
      { question: '', options: '', answer: '' },
      { question: '', options: '', answer: '' },
    ],
  };
  const access_token = fetchAccessToken();
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    let newHomeWorkdata = _.cloneDeep(homeworkData);
    if (selectedFlatRows.length > 0) {
      let selectedData = selectedFlatRows[0]?.original;
      newHomeWorkdata.series = { value: selectedData.series_id, label: selectedData.series_name };
      newHomeWorkdata.school = { value: selectedData.school_id, label: selectedData.school_name };
      newHomeWorkdata.subjects = {
        value: selectedData.subject_id,
        label: selectedData.subject_name,
      };
      newHomeWorkdata.standard = {
        value: selectedData.standard_id,
        label: selectedData.standard_name,
      };
      newHomeWorkdata.lessons = { value: selectedData.lesson_id, label: selectedData.lesson_name };
      newHomeWorkdata.homework = selectedData.homework;
      newHomeWorkdata.date = selectedData.date;
      newHomeWorkdata.active = selectedData.active;
    }
    setHomeworkData(newHomeWorkdata);
  }, []); // The empty dependency array means the effect runs once after the initial render

  //   const initialValues = {
  //     series_list: [],
  //     standard_list: [],
  //     subject_list: [],
  //     lesson: '',
  //     part_list: [{ part: '', live: '', animation: '' }],
  //     image_list: [{ image: '' }],
  //     active: '',
  //     excelFile: null, // Add initial value for the file
  //   };

  const checkFields = (homeworkData) => {
    const newRequiredFields = _.cloneDeep(requiredFields);
    // if (homeworkData.length == 0) {
    //   newRequiredFields.seriesRequired = true;
    // }

    if (homeworkData.series == undefined || homeworkData.series.length == 0) {
      newRequiredFields.seriesRequired = true;
    }
    if (homeworkData.standard == undefined || homeworkData.standard.length == 0) {
      newRequiredFields.standardsRequired = true;
    }
    if (homeworkData.subjects == undefined || homeworkData.subjects.length == 0) {
      newRequiredFields.subjectsRequired = true;
    }
    if (homeworkData.lessons == undefined || homeworkData.lessons.length == 0) {
      newRequiredFields.lessonsRequired = true;
    }
    if (homeworkData.date == undefined || homeworkData.date.length == 0) {
      newRequiredFields.dateRequired = true;
    }
    if (homeworkData.homework == undefined || homeworkData.homework.length == 0) {
      newRequiredFields.homeworkRequired = true;
    }
    if (homeworkData.school == undefined || homeworkData.school.length == 0) {
      newRequiredFields.schoolRequired = true;
    }

    if (!homeworkData.active || homeworkData.active == undefined) {
      newRequiredFields.statusRequired = true;
    }

    setRequiredFields(newRequiredFields);
    if (
      !newRequiredFields.seriesRequired &&
      !newRequiredFields.standardsRequired &&
      !newRequiredFields.subjectsRequired &&
      !newRequiredFields.dateRequired &&
      !newRequiredFields.schoolRequired &&
      !newRequiredFields.lessonsRequired &&
      !newRequiredFields.statusRequired &&
      !newRequiredFields.homeworkRequired
    ) {
      return {
        status: true,
      };
    } else {
      return {
        status: false,
      };
    }
  };

  return (
    <>
      {
        <Formik
          //   initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {}}
        >
          {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => (
            <FormikForm>
              <Modal
                backdrop="static"
                keyboard={false}
                className="modal-right fade"
                show={isOpen}
                onHide={onCloseEmptyModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {whichModal.slice(0, 1).toUpperCase()}
                    {whichModal.slice(1)} Homework
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={seriesData}
                      isDisabled={true}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          backgroundColor: state.isDisabled ? '#f0f0f0' : base.backgroundColor,
                          borderColor: state.isDisabled ? '#d3d3d3' : base.borderColor,
                          cursor: state.isDisabled ? 'not-allowed' : base.cursor,
                          opacity: state.isDisabled ? 0.8 : 1,
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#999',
                        }),
                        singleValue: (base, state) => ({
                          ...base,
                          color: state.isDisabled ? '#999' : base.color,
                        }),
                      }}
                      value={homeworkData.series}
                      placeholder="Select Series"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />

                    {requiredFields.seriesRequired && (
                      <div className="d-block invalid-tooltip">
                        Please select at least one series
                      </div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={schoolData}
                      isDisabled={true}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          backgroundColor: state.isDisabled ? '#f0f0f0' : base.backgroundColor,
                          borderColor: state.isDisabled ? '#d3d3d3' : base.borderColor,
                          cursor: state.isDisabled ? 'not-allowed' : base.cursor,
                          opacity: state.isDisabled ? 0.8 : 1,
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#999',
                        }),
                        singleValue: (base, state) => ({
                          ...base,
                          color: state.isDisabled ? '#999' : base.color,
                        }),
                      }}
                      value={homeworkData.school}
                      placeholder="Select School"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.schoolRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select school</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={standardData}
                      isDisabled={true}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          backgroundColor: state.isDisabled ? '#f0f0f0' : base.backgroundColor,
                          borderColor: state.isDisabled ? '#d3d3d3' : base.borderColor,
                          cursor: state.isDisabled ? 'not-allowed' : base.cursor,
                          opacity: state.isDisabled ? 0.8 : 1,
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#999',
                        }),
                        singleValue: (base, state) => ({
                          ...base,
                          color: state.isDisabled ? '#999' : base.color,
                        }),
                      }}
                      value={homeworkData.standard}
                      placeholder="Select Standard"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.standardsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select standard</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={subjectData}
                      isDisabled={true}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          backgroundColor: state.isDisabled ? '#f0f0f0' : base.backgroundColor,
                          borderColor: state.isDisabled ? '#d3d3d3' : base.borderColor,
                          cursor: state.isDisabled ? 'not-allowed' : base.cursor,
                          opacity: state.isDisabled ? 0.8 : 1,
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#999',
                        }),
                        singleValue: (base, state) => ({
                          ...base,
                          color: state.isDisabled ? '#999' : base.color,
                        }),
                      }}
                      value={homeworkData.subjects}
                      placeholder="Select Subject"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.subjectsRequired && (
                      <div className="d-block invalid-tooltip">Please select subject</div>
                    )}
                  </div>

                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={lessonData}
                      isDisabled={true}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          backgroundColor: state.isDisabled ? '#f0f0f0' : base.backgroundColor,
                          borderColor: state.isDisabled ? '#d3d3d3' : base.borderColor,
                          cursor: state.isDisabled ? 'not-allowed' : base.cursor,
                          opacity: state.isDisabled ? 0.8 : 1,
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#999',
                        }),
                        singleValue: (base, state) => ({
                          ...base,
                          color: state.isDisabled ? '#999' : base.color,
                        }),
                      }}
                      value={homeworkData.lessons}
                      placeholder="Select Lesson"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.lessonsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select lesson</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <DatePicker
                      selected={
                        homeworkData.date ? new Date(homeworkData.date.replace(/\//g, '-')) : null
                      }
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select Date"
                      className="form-control"
                      disabled // Disable the DatePicker
                    />
                    {requiredFields.dateRequired && (
                      <div className="d-block invalid-tooltip">Please select a date</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <textarea
                      style={{ height: '100px' }}
                      className="form-control"
                      name="homework"
                      placeholder="Enter homework details here"
                      value={homeworkData.homework || ''}
                      disabled // Disable the textarea
                    />
                    {requiredFields.homeworkRequired && (
                      <div className="d-block invalid-tooltip">Please enter homework</div>
                    )}
                  </div>
                  <div className="mb-3 filled tooltip-end-top">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="radio"
                      label="On"
                      value="on"
                      id="statusRadio1"
                      name="active"
                      checked={homeworkData.active === 'on'}
                      disabled // Disable the radio buttons
                    />
                    <Form.Check
                      type="radio"
                      label="Off"
                      value="off"
                      id="statusRadio2"
                      name="active"
                      checked={homeworkData.active === 'off'}
                      disabled // Disable the radio buttons
                    />
                    {requiredFields.statusRequired === true && (
                      <div className="d-block invalid-tooltip">Please select status</div>
                    )}
                  </div>
                </Modal.Body>

                {isSuccess && (
                  <div
                    className={`card mb-3 ${
                      successMessage.status ? 'bg-success' : 'bg-danger'
                    } text-white`} // Dynamically set the background color based on success or failure
                    style={{ marginTop: '5px' }}
                  >
                    <div className="card-body">
                      <p className="card-text">{successMessage.message}</p>
                    </div>
                  </div>
                )}
              </Modal>
            </FormikForm>
          )}
        </Formik>
      }
    </>
  );
};

export default ModelViewHomeWork;
