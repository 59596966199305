import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const ControlsEdit = ({ tableInstance }) => {
  const { selectedFlatRows, setIsOpen, onOpenEditModal } = tableInstance;
  const editButtonClick = () => {
    // toggleAllPageRowsSelected(false);
    // setIsOpenAddEditDeleteModal(true);

    onOpenEditModal();
  };

  if (selectedFlatRows.length !== 1) {
    return (
      <Button
        variant="foreground-alternate"
        className="btn-icon btn-icon-only shadow edit-datatable"
        disabled
      >
        <CsLineIcons icon="edit" />
      </Button>
    );
  }

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top-edit">Edit</Tooltip>}>
      <Button
        onClick={editButtonClick}
        variant="foreground-alternate"
        className="btn-icon btn-icon-only shadow edit-datatable"
      >
        <CsLineIcons icon="edit" />
      </Button>
    </OverlayTrigger>
  );
};
export default ControlsEdit;
