/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';
import LessonParts from 'views/learning/LessonParts';
import HomeWork from 'views/utilities/homework';
import DayPlan from 'views/utilities/dayPlan';
import Alert from 'views/utilities/Alerts';
// import AssessmentQuetions from 'views/utilities/tables/assessment/components/ViewAssessmentQuesions';

const assessmentQuestions = {
  questions: lazy(()=>import('views/utilities/tables/assessment/components/ViewAssessmentQuestions'))
}

const blog = lazy(() => import('views/blog/Blog'));
const community = {
  index: lazy(() => import('views/community/Community')),
  list: lazy(() => import('views/community/CommunityList')),
};
const upgrade = lazy(() => import('views/upgrade/Upgrade'));

const dashboard = {
  gettingStarted: lazy(() => import('views/dashboard/DashboardGettingStarted')),
  analysis: lazy(() => import('views/dashboard/DashboardAnalysis')),
};
const learning = {
  classes: lazy(() => import('views/learning/Classes')),
  standards: lazy(() => import('views/learning/Standards')),
  lessonParts: lazy(() => import('views/learning/LessonParts')),
};
const utilities = {
  series: lazy(() => import('views/utilities/Series')),
  standard: lazy(() => import('views/utilities/Standard')),
  subject: lazy(() => import('views/utilities/Subject')),
  lesson: lazy(() => import('views/utilities/Lesson')),
  assessment: lazy(() => import('views/utilities/Assessment')),
  exam : lazy(()=>import('views/utilities/Exam')),
  homework : lazy(()=>import('views/utilities/homework')),
  subjects : lazy(()=>import('views/utilities/tables/homework/homeworkSubjects')),
  dayPlanSubjects : lazy(()=>import('views/utilities/tables/dayPlan/dayPlanSubjects')),
  dayPlan : lazy(()=>import('views/utilities/dayPlan')),
  alert : lazy(()=>import('views/utilities/Alerts')),
  Ebook: lazy(() => import('views/utilities/Ebook')),
  teacherResources : lazy(()=>import('views/utilities/tables/teacherResource/teacherResource')),
  onlineTraining : lazy(()=>import('views/utilities/tables/onlineTraining/onlineTraining')),
  eBookIframe : lazy(()=>import('views/utilities/tables/E-books/ebookComponent')),
  pdfView : lazy(()=>import('views/utilities/tables/teacherResource/pdfView')),
  Preparation: lazy(() => import('views/utilities/preparation')),

};
const school_services = {
  distributor: lazy(() => import('views/utilities/Distributor')),
  school: lazy(() => import('views/utilities/School')),
  section: lazy(() => import('views/utilities/Section')),
  student: lazy(() => import('views/utilities/Student')),
  teacher : lazy(() => import('views/utilities/TeacherNew'))
};
const teacher_services = {
  tattendance: lazy(() => import('views/utilities/TAttendance')),
  studentAtttendenceTable : lazy(() => import('views/utilities/tables/tattendance/TAttendanceTable')),
  studentReports : lazy(() => import('views/utilities/studentReports'))
};
const student_services = {
  classes: lazy(() => import('views/utilities/StClasses')),
  standards: lazy(() => import('views/utilities/tables/stclasses/Standards')),
  lessonParts: lazy(() => import('views/utilities/tables/stclasses/LessonParts')),
};
const services = {
  database: lazy(() => import('views/services/ServicesDatabase')),
  databaseAdd: lazy(() => import('views/services/ServicesDatabaseAdd')),
  databaseDetail: lazy(() => import('views/services/ServicesDatabaseDetail')),
  storage: lazy(() => import('views/services/ServicesStorage')),
  storageFolder: lazy(() => import('views/services/ServicesStorageFolder')),
  hosting: lazy(() => import('views/services/ServicesHosting')),
  users: lazy(() => import('views/services/ServicesUsers')),
};
const account = {
  settings: lazy(() => import('views/account/AccountSettings')),
  billing: lazy(() => import('views/account/AccountBilling')),
  security: lazy(() => import('views/account/AccountSecurity')),
};
const support = {
  docs: lazy(() => import('views/support/SupportDocs')),
  docsDetail: lazy(() => import('views/support/SupportDocsDetail')),
  knowledgeBase: lazy(() => import('views/support/SupportKnowledgeBase')),
  tickets: lazy(() => import('views/support/SupportTickets')),
  ticketsDetail: lazy(() => import('views/support/SupportTicketsDetail')),
};


const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/learning`,
    },
    // {
    //   path: `${appRoot}/learning`,
    //   exact: true,
    //   redirect: true,
    //   label: 'menu.learning',
    //   icon: 'online-class',
    //   component: learning.classes,
    // },
  ],
  sidebarItems: [
    {
      path: `${appRoot}/learning`,
      label: 'menu.learning',
      icon: 'grid-1',
      exact: true,
      redirect: true,
      to: `${appRoot}/learning/classes`,
      subs: [
        {
          path: '/classes',
          label: 'menu.classes',
          icon: 'online-class',
          component: learning.classes,
        },
        {
          path: '/standards',
          label: 'menu.standards',
          hideInMenu: true,
          component: learning.standards,
        },
        {
          path: '/parts',
          label: 'menu.parts',
          hideInMenu: true,
          component: learning.lessonParts,
        },
      ],
    },
    {
      path: `${appRoot}/utilities`,
      label: 'menu.utilities',
      icon: 'grid-1',
      exact: true,
      redirect: true,
      to: `${appRoot}/utilities/series`,
      subs: [
        {
          path: '/series',
          label: 'menu.series',
          icon: 'list',
          component: utilities.series,
        },
        {
          path: '/standard',
          label: 'menu.standard',
          icon: 'abacus',
          component: utilities.standard,
        },
        {
          path: '/subject',
          label: 'menu.subject',
          icon: 'book-open',
          component: utilities.subject,
        },
        {
          path: '/lesson',
          label: 'menu.lesson',
          icon: 'science',
          component: utilities.lesson,
        },
        {
          path: '/assessment',
          label: 'menu.assessment',
          icon: 'quiz',
          component: utilities.assessment,
        },
        {
          path: '/exam',
          label: 'menu.exam',
          icon: 'server',
          component: utilities.exam,
        },
        // {
        //   path: '/homework',
        //   label: 'menu.homework',
        //   icon: 'quiz',
        //   component: utilities.homework,
        // },
         {
          path: '/subjects',
          label: 'menu.homework',
          icon: 'book',
          component: utilities.subjects,
        },
        {
          path: '/day_plan_subjects',
          label: 'menu.dayPlan',
          icon: 'calendar',
          component: utilities.dayPlanSubjects,
        },
        {
          path: '/alert',
          label: 'menu.alert',
          icon: 'bell',
          component: utilities.alert,
        },
        {
          path: '/distributor',
          label: 'menu.distributor',
          icon: 'diagram-1',
          component: school_services.distributor,
        },
        {
          path: '/school',
          label: 'menu.school',
          icon: 'diploma',
          component: school_services.school,
        },
        {
          path: '/ebook',
          label: 'menu.eBook',
          icon: 'diploma',
          component: utilities.Ebook,
        },
        {
          path: '/teacherResource',
          label: 'menu.teacherResource',
          icon: 'diploma',
          component: utilities.teacherResources,
        },
        {
          path: '/onlineTraining',
          label: 'menu.onlineTraining',
          icon: 'diploma',
          component: utilities.onlineTraining,
        },
        {
          path: '/preparation',
          label: 'menu.preparation',
          icon: 'diploma',
          component: utilities.Preparation,
        },
      ],
    },
    {
      path: `${appRoot}/school_services`,
      label: 'menu.school_services',
      icon: 'grid-1',
      exact: true,
      redirect: true,
      to: `${appRoot}/school_services/distributor`,
      subs: [
        {
          path: '/section',
          label: 'menu.section',
          icon: 'tag',
          component: school_services.section,
        },
        {
          path: '/teacher',
          label: 'menu.teacher',
          icon: 'board-1',
          component: school_services.teacher,
        },
      ],
    },
    {
      path: `${appRoot}/teacher_services`,
      label: 'menu.teacher_services',
      icon: 'grid-1',
      exact: true,
      redirect: true,
      to: `${appRoot}/teacher_services/tattendance`,
      subs: [
        {
          path: '/tattendance',
          label: 'menu.tattendance',
          icon: 'chart-3',
          component: teacher_services.tattendance,
        },
        {
          path: '/student',
          label: 'menu.student',
          icon: 'user',
          component: school_services.student,
        },
        {
          path: '/reports',
          label: 'menu.reports',
          icon: 'layout-1',
          component: teacher_services.studentReports,
        },
      ],
    },
    {
      path: `${appRoot}/assessment`,
      // icon: 'home-garage',
      // label: 'menu.dashboards',
      exact: true,
      redirect: true,
      to: `${appRoot}/assessment/quesions/:id`,
      subs: [
        { path: '/quesions/:id', label: 'menu.quesions', component: assessmentQuestions.questions },
        // { path: '/school', label: 'menu.school', component: dashboards.school },
      ],
    },
    // {
    //   path: `${appRoot}/student_services`,
    //   label: 'menu.student_services',
    //   icon: 'grid-1',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/student_services/classes`,
    //   subs: [
    //     {
    //       path: '/classes',
    //       label: 'menu.classes',
    //       icon: 'online-class',
    //       component: student_services.classes,
    //     },
    //     {
    //       path: '/standards',
    //       label: 'menu.standards',
    //       hideInMenu: true,
    //       component: student_services.standards,
    //     },
    //     {
    //       path: '/parts',
    //       label: 'menu.parts',
    //       hideInMenu: true,
    //       component: student_services.lessonParts,
    //     },
    //   ],
    // },
    // {
    //   path: `${appRoot}/services`,
    //   label: 'menu.services',
    //   icon: 'grid-1',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/services/database`,
    //   subs: [
    //     {
    //       path: '/database',
    //       label: 'menu.database',
    //       icon: 'database',
    //       component: services.database,
    //       subs: [
    //         {
    //           path: '/add',
    //           label: 'menu.database-add',
    //           hideInMenu: true,
    //           component: services.databaseAdd,
    //         },
    //         {
    //           path: '/detail',
    //           label: 'menu.database-detail',
    //           hideInMenu: true,
    //           component: services.databaseDetail,
    //         },
    //       ],
    //     },
    //     {
    //       path: '/storage',
    //       label: 'menu.storage',
    //       icon: 'file-image',
    //       component: services.storage,
    //       subs: [
    //         {
    //           path: '/folder',
    //           label: 'menu.storage',
    //           hideInMenu: true,
    //           component: services.storageFolder,
    //         },
    //       ],
    //     },
    //     { path: '/storage-folder', component: services.storageFolder, hideInMenu: true },
    //     { path: '/hosting', label: 'menu.hosting', icon: 'router', component: services.hosting },
    //     { path: '/users', label: 'menu.users', icon: 'user', component: services.users },
    //   ],
    // },
    // {
    //   path: `${appRoot}/account`,
    //   label: 'menu.account',
    //   icon: 'user',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/account/settings`,
    //   subs: [
    //     { path: '/settings', label: 'menu.settings', icon: 'gear', component: account.settings },
    //     {
    //       path: '/billing',
    //       label: 'menu.billing',
    //       icon: 'credit-card',
    //       component: account.billing,
    //     },
    //     { path: '/security', label: 'menu.security', icon: 'shield', component: account.security },
    //   ],
    // },
    // {
    //   path: `${appRoot}/support`,
    //   label: 'menu.support',
    //   icon: 'help',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/support/docs`,
    //   subs: [
    //     {
    //       path: '/docs',
    //       label: 'menu.docs',
    //       icon: 'file-empty',
    //       component: support.docs,
    //       subs: [
    //         {
    //           path: '/detail',
    //           label: 'menu.docs',
    //           icon: 'file-empty',
    //           component: support.docsDetail,
    //           hideInMenu: true,
    //         },
    //       ],
    //     },
    //     {
    //       path: '/knowledge-base',
    //       label: 'menu.knowledge-base',
    //       icon: 'notebook-1',
    //       component: support.knowledgeBase,
    //     },
    //     {
    //       path: '/tickets',
    //       label: 'menu.tickets',
    //       icon: 'bookmark',
    //       component: support.tickets,
    //       subs: [
    //         {
    //           path: '/detail',
    //           label: 'menu.tickets-detail',
    //           icon: 'file-empty',
    //           component: support.ticketsDetail,
    //           hideInMenu: true,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
          path: '/homework',
          // label: 'menu.homework',
          // icon: 'quiz',
          component: utilities.homework,
        },
        {
          path: '/day_plan',
          // label: 'menu.dayPlan',
          // icon: 'quiz',
          component:DayPlan,
        },

        {
          path: '/alerts',
          // label: 'menu.alerts',
          // icon: 'quiz',
          component:Alert,
        },
        
        {
          path: '/attendance_submit_form',
          // label: 'menu.homework',
          // icon: 'quiz',
          component: teacher_services.studentAtttendenceTable,
        },
        {
          path: '/iframe',
          // label: 'menu.homework',
          // icon: 'quiz',
          component: utilities.eBookIframe,
        },
        {
          path: '/view-pdf',
          // label: 'menu.homework',
          // icon: 'quiz',
          component: utilities.pdfView,
        },
        
        
  ],
};
export default routesAndMenuItems;
