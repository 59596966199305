import React, { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  assessment: yup.string().required('Assessment is required'),
  active: yup.string().required('Status is required'),
});

const ModalEditExam = ({ tableInstance }) => {
  const { isOpen, onCloseEmptyModal, whichModal, onCloseEditModal, selectedFlatRows } =
    tableInstance;

  useEffect(() => {
    console.log(selectedFlatRows);
  }, [selectedFlatRows]);

  const initialValues = {
    id: selectedFlatRows[0].original.id,
    assessment: selectedFlatRows[0].original.name,
    active: selectedFlatRows[0].original.active,
  };

  const onSubmit = (values) => {
    console.log(values);
    onCloseEditModal(values);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => (
        <FormikForm onSubmit={handleSubmit}>
          <Modal
            backdrop="static"
            keyboard={false}
            className="modal-right fade"
            show={isOpen}
            onHide={onCloseEmptyModal}
          >
            <Modal.Header>
              <Modal.Title>
                {whichModal.slice(0, 1).toUpperCase()}
                {whichModal.slice(1)} Assessment
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {/* <CsLineIcons icon="list" /> */}
              {/* <Form.Label>Assessment Name</Form.Label> */}
              <Form.Floating className="mb-3 filled tooltip-end-top">
                <Form.Control
                  id="floatingInputCustom"
                  type="text"
                  name="assessment"
                  placeholder="Enter Assessment"
                  value={values.assessment}
                  onChange={(event) => {
                    setFieldValue('assessment', event.target.value);
                  }}
                />
                <label htmlFor="floatingInputCustom">Assessment Name</label>

                {errors.assessment && touched.assessment && (
                  <div className="d-block invalid-tooltip">{errors.assessment}</div>
                )}
              </Form.Floating>

              <div className="mb-3 filled tooltip-end-top">
                <Form.Label>Status</Form.Label>
                <Form.Check
                  type="radio"
                  label="On"
                  value="on"
                  id="statusRadio1"
                  name="active"
                  checked={values.active === 'on'}
                  onChange={(event) => {
                    setFieldValue('active', event.target.value);
                  }}
                />
                <Form.Check
                  type="radio"
                  label="Off"
                  value="off"
                  id="statusRadio2"
                  name="active"
                  checked={values.active === 'off'}
                  onChange={(event) => {
                    setFieldValue('active', event.target.value);
                  }}
                />
                {errors.active && touched.active && (
                  <div className="d-block invalid-tooltip">{errors.active}</div>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="outline-primary" onClick={onCloseEmptyModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Edit
              </Button>
            </Modal.Footer>
          </Modal>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ModalEditExam;
