import { createSlice } from '@reduxjs/toolkit';

const seriesSlice = createSlice({
  name: 'series',
  initialState: {
    seriesData: [],
  },
  reducers: {
    setSeriesStateFull(state, action) {
      state.seriesData = action.payload;
    },
  },
});

export const { setSeriesStateFull } = seriesSlice.actions;
export default seriesSlice.reducer;
