import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'reduxjs-toolkit-persist/es/constants';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import scrollspyReducer from 'components/scrollspy/scrollspySlice';
import langReducer from 'lang/langSlice';
import menuReducer from 'layout/nav/main-menu/menuSlice';
import settingsReducer from 'settings/settingsSlice';
import notificationReducer from 'layout/nav/notifications/notificationSlice';
import authReducer from 'auth/authSlice';
import layoutReducer from 'layout/layoutSlice';
import { REDUX_PERSIST_KEY } from 'config.js';
import { onlineClassesReducer } from 'slices/onlineClasses';
import { seriesReducer } from 'slices/series';
import { standardReducer } from 'slices/standard';
import { subjectReducer } from 'slices/subject';
import { SubjectByStandardApi, SubjectByStandardApiReducer } from 'slices/get/subjectByStandard';
import { LessonByRoleApi } from 'slices/get/lessonByRole';
import { SeriesApi } from 'slices/get/series';
import { StandardApi } from 'slices/get/standard';
import { SubjectApi } from 'slices/get/subject';
import { DistributorApi } from 'slices/get/distributor';
import { SchoolApi } from 'slices/get/school';
import { StudentApi } from 'slices/get/student';
import { LessonApi } from 'slices/get/lesson';
import { TAttendanceApi } from 'slices/get/tattendance';
import { SectionApi } from 'slices/get/section';
import { AssessmentApi } from 'slices/get/assessment';
import { TeacherApi } from 'slices/get/teacher';
import { CommonApi } from 'slices/get/common';
import series from 'slices/store/series';
import standard from 'slices/store/standard';
import subject from 'slices/store/subject';
import lesson from 'slices/store/lesson';

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['menu', 'settings', 'lang'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    settings: settingsReducer,
    layout: layoutReducer,
    lang: langReducer,
    auth: authReducer,
    menu: menuReducer,
    notification: notificationReducer,
    scrollspy: scrollspyReducer,
    series: seriesReducer,
    standard: standardReducer,
    onlineClasses: onlineClassesReducer,
    [SubjectByStandardApi.reducerPath]: SubjectByStandardApi.reducer,
    [LessonByRoleApi.reducerPath]: LessonByRoleApi.reducer,
    [SeriesApi.reducerPath]: SeriesApi.reducer,
    [StandardApi.reducerPath]: StandardApi.reducer,
    [SubjectApi.reducerPath]: SubjectApi.reducer,
    [DistributorApi.reducerPath]: DistributorApi.reducer,
    [SchoolApi.reducerPath]: SchoolApi.reducer,
    [StudentApi.reducerPath]: StudentApi.reducer,
    [LessonApi.reducerPath]: LessonApi.reducer,
    [TAttendanceApi.reducerPath]: TAttendanceApi.reducer,
    [SectionApi.reducerPath]: SectionApi.reducer,
    [AssessmentApi.reducerPath]: AssessmentApi.reducer,
    [TeacherApi.reducerPath]: TeacherApi.reducer,
    [CommonApi.reducerPath]: CommonApi.reducer,
    series: series,
    standard: standard,
    subject: subject,
    lesson: lesson,
  })
);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(SubjectByStandardApi.middleware)
      .concat(LessonByRoleApi.middleware)
      .concat(SeriesApi.middleware)
      .concat(StandardApi.middleware)
      .concat(SubjectApi.middleware)
      .concat(DistributorApi.middleware)
      .concat(SchoolApi.middleware)
      .concat(StudentApi.middleware)
      .concat(LessonApi.middleware)
      .concat(TAttendanceApi.middleware)
      .concat(SectionApi.middleware)
      .concat(AssessmentApi.middleware)
      .concat(TeacherApi.middleware)
      .concat(CommonApi.middleware),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
