import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import HtmlHead from 'components/html-head/HtmlHead';
import { Row, Col, Card, Tab, Tabs, Button } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { useGetSubjectByStandardMutation } from 'slices/get/subjectByStandard';
import { useGetLessonByRoleMutation } from 'slices/get/lessonByRole';
import BoxedVariationsStripe from './components/BoxedVariationsStripe';
import BoxedVariationsStripeParts from './components/BoxedVariationStripeParts';
import useService from 'views/default/useService';
import { useGetAllLessonMutation } from 'slices/get/lesson';

function LessonParts() {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.currentPath;
  const searchParams = new URLSearchParams(location.search);

  const series = searchParams.get('series') || '';
  const seriesID = searchParams.get('seriesID');
  const standardID = searchParams.get('standardID');
  const standard = searchParams.get('standard');
  const subjectID = searchParams.get('subjectID');
  const subject = searchParams.get('subject') || '';
  const lesson = searchParams.get('lesson');
  const lessonID = searchParams.get('lessonID');

  const [partsData, setPartsData] = useState([]);

  const title = searchParams && standard && `${lesson}`;
  const description =
    searchParams &&
    lesson &&
    `List of ${lesson
      .split('_')
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
      .join(' ')}'s Parts`;

  const { checkAccessTime, fetchAccessToken } = useService();

  const [getLessonByRole] = useGetLessonByRoleMutation();
  const [getAllLesson] = useGetAllLessonMutation();

  useEffect(() => {
    console.log('Series:', series);
    console.log('SeriesID:', seriesID);
    console.log('Standard ID:', standardID);
    console.log('Standard:', standard);
    console.log('Subject ID:', subjectID);
    console.log('Subject:', subject);

    console.log(searchParams);

    if (
      !searchParams ||
      !series ||
      !seriesID ||
      !standardID ||
      !standard ||
      !subjectID ||
      !subject
    ) {
      history.push('/learning/classes');
    } else {
      const access_token = fetchAccessToken();

      getAllLesson({ ep: '/lesson/data', token: access_token }).then((response) => {
        console.log(response);
        console.log('Custom data from after_request:', response);

        checkAccessTime(response, currentPath);

        const parsedData = response.data.data.map((item) => {
          return {
            ...item,
            part_list: JSON.parse(item.part_list),
            series_list: JSON.parse(item.series_list),
            standard_list: JSON.parse(item.standard_list),
            subject_list: JSON.parse(item.subject_list),
          };
        });

        console.log(
          'LessonParts: 83',
          'parsedData',
          parsedData,
          parsedData.filter((v) => v.series_id.split(',').includes(seriesID)),
          parsedData.filter(
            (v) => v.series_id.split(',').includes(seriesID) && v.standard_id === standardID
          ),
          parsedData.filter(
            (v) =>
              v.series_id.split(',').includes(seriesID) &&
              v.standard_id === standardID &&
              v.subject_id === subjectID
          ),
          parsedData.filter(
            (v) =>
              v.series_id.split(',').includes(seriesID) &&
              v.standard_id === standardID &&
              v.subject_id === subjectID &&
              v.id === Number(lessonID)
          )
        );

        setPartsData(
          parsedData.filter(
            (v) =>
              v.series_id.split(',').includes(seriesID) &&
              v.standard_id === standardID &&
              v.subject_id === subjectID &&
              v.id === Number(lessonID)
          )[0].part_list
        );
      });
    }
  }, [location.search]);

  useEffect(() => {
    console.log(partsData);
  }, [partsData]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Col>
        {/* Title Start */}

        <div className="page-title-container mb-3">
          <Button
            variant="link"
            style={{ paddingLeft: 0 }}
            onClick={() => history.push(`/learning/classes?series=${series}`)}
          >
            <CsLineIcons icon="chevron-left" size="13" />
            <span className="align-middle">{`${series
              .split('_')
              .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
              .join(' ')}`}</span>
          </Button>
          <Button
            variant="link"
            style={{ paddingLeft: 0 }}
            onClick={() => {
              console.log(
                `/learning/standards?series=${series}&seriesID=${seriesID}&standard=${standard}&standardID=${standardID}`
              );

              history.push(
                `/learning/standards?series=${series}&seriesID=${seriesID}&standard=${standard}&standardID=${standardID}`
              );
            }}
          >
            <CsLineIcons icon="chevron-left" size="13" />
            <span className="align-middle">{`${standard
              .split(' ')
              .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
              .join(' ')}`}</span>
          </Button>
          <Button
            variant="link"
            style={{ paddingLeft: 0 }}
            onClick={() => {
              console.log(
                `/learning/standards?series=${series}&seriesID=${seriesID}&standard=${standard}&standardID=${standardID}&subject=${subject}&subjectID=${subjectID}`
              );

              history.push(
                `/learning/standards?series=${series}&seriesID=${seriesID}&standard=${standard}&standardID=${standardID}&subject=${subject}&subjectID=${subjectID}`
              );
            }}
          >
            <CsLineIcons icon="chevron-left" size="13" />
            <span className="align-middle">{`${subject
              .split(' ')
              .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
              .join(' ')}`}</span>
          </Button>

          <Row>
            <Col className="mb-2">
              <h1 className="mb-2 pb-0 display-4">{title}</h1>
              <div className="text-muted font-heading text-small">{description}</div>
            </Col>
          </Row>
        </div>
        {/* Title End */}

        <section className="scroll-section" id="responsiveTabs">
          <Card className="mb-3">
            <Card.Body>
              <div className="mb-5">
                <section className="scroll-section" id="stripe">
                  <BoxedVariationsStripeParts
                    data={partsData}
                    series={series}
                    seriesID={seriesID}
                    standardID={standardID}
                    standard={standard}
                    subject={subject}
                    subjectID={subjectID}
                    lesson={lesson}
                    lessonID={lessonID}
                  />
                </section>
              </div>
            </Card.Body>
          </Card>
        </section>
        {/* Tabbed Interfaces End */}
      </Col>
    </>
  );
}

export default LessonParts;
