import { createSlice } from '@reduxjs/toolkit';

const lessonAndPartsSlice = createSlice({
  name: 'lessonAndParts',
  initialState: {
    lessonData: [],
  },
  reducers: {
    setLessonAndPartsStateFull(state, action) {
      state.lessonData = action.payload;
    },
  },
});

export const { setLessonAndPartsStateFull } = lessonAndPartsSlice.actions;
export default lessonAndPartsSlice.reducer;
